import React from "react";
import { useMediaQuery } from "react-responsive";
import { useAppState } from "../../AppContext";
import { breakpoints } from "../../utils/styleUtils";

type IsMobileProps = {
  children: React.ReactNode;
};

const IsMobile = ({ children }: IsMobileProps) => {
  const { state } = useAppState();
  const isBigScreen = useMediaQuery({ query: breakpoints[10] });
  return isBigScreen || state.initializing ? null : <>{children}</>;
};

export default IsMobile;
