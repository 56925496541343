/*------------------------------------*\
  #COMPONENTS-SURVEY
\*------------------------------------*/

import styled from "styled-components";
import tick from "../../images/tick.svg";
import map from "../../images/map.svg";
import camera from "../../images/camera.svg";
import MapButton from "../../images/map-button-placeholder.png";
import { addOpacity, breakpoints } from "../../utils/styleUtils";

const ratingColors = ["#f5455e", "#ff8f2c", "#fee01f", "#82d63a", "#0dcc97"];

/*
 * Survey
 */
export const ParticipationWrapper = styled.div`
  /* max-height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; */

  .sv_main {
    .mobile-map-button {
      width: 100%;
      height: 8rem;
      background: linear-gradient(rgba(31, 59, 230, 0.5), rgba(31, 59, 230, 0.5)), url(${MapButton});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      line-height: 8rem;
      color: white;
      font-size: var(--font-size-50);
      text-align: center;
      border-radius: var(--border-radius-default);

      @media ${breakpoints[30]} {
        display: none;
      }
    }

    .sv_p_root > div > span {
      display: block;
      line-height: var(--line-height-20);
      color: var(--color-neutral-50);
      margin: var(--space-stack-30);
    }

    button + button {
      margin-left: var(--space-default);
    }
  }

  .sv_completed_page {
    padding-bottom: 20px;
  }

  .sv_qstn {
    overflow: unset;
  }

  .sv-hidden,
  .sv-visuallyhidden {
    display: none;
  }

  /*
 * Question basics
 */
  .sv_q {
    margin: var(--space-stack-30);
  }

  /* Title */
  .sv_q_title {
    display: block;
    font-size: var(--font-size-40);
    font-weight: 500;
    margin: var(--space-stack-20);
  }

  /* Description */
  .sv_q_description {
    display: block;
    font-size: var(--font-size-20);
    line-height: var(--line-height-20);
    color: var(--color-neutral-50);
    margin: var(--space-stack-default);
  }

  /* Bullet list */
  .sv_main ul {
    margin: var(--space-stack-default);
    list-style: none;

    li {
      line-height: var(--line-height-20);
      padding-left: 1.75rem;
      position: relative;

      &:before {
        position: absolute;
        left: 0.5rem;
        top: 0.55rem;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: var(--color-primary-50);
        content: " ";
      }
    }
  }

  /* Error message */
  .sv_q_erbox {
    margin-top: var(--space-20);
    font-size: var(--font-size-20);
    font-weight: 500;
    display: inline-block;
    background: var(--color-error-30);
    color: var(--color-error-70);
    border-radius: var(--border-radius-default);
    padding: var(--space-20);
  }

  /*
 * Rating
 */
  .sv_q_rating {
    border-radius: var(--border-radius-default);
    box-shadow: var(--box-shadow-20);
    display: inline-flex;
    margin-bottom: var(--space-40);
    position: relative;

    fieldset {
      display: flex;
    }
  }

  /* Item */
  .sv_q_rating_item {
    cursor: pointer;
    flex-grow: 1;

    /* & input:checked + .sv_q_rating_item_text {
    box-shadow: inset 0px 0px 0px 2px var(--color-primary-50), inset 0px 0px 0px 4px var(--color-primary-20);
  } */

    &:nth-of-type(1) {
      background-color: ${addOpacity(ratingColors[0], .25)};
      &.active {
        background-color: ${ratingColors[0]};
        color: var(--color-white);
      }
    }
    &:nth-of-type(2) {
      background-color: ${addOpacity(ratingColors[1], .25)};
      &.active {
        background-color: ${ratingColors[1]};
        color: var(--color-white);
      }
    }
    &:nth-of-type(3) {
      background-color: ${addOpacity(ratingColors[2], .25)};
      &.active {
        background-color: ${ratingColors[2]};
        color: var(--color-white);
      }
    }
    &:nth-of-type(4) {
      background-color: ${addOpacity(ratingColors[3], .25)};
      &.active {
        background-color: ${ratingColors[3]};
        color: var(--color-white);
      }
    }
    &:nth-of-type(5) {
      background-color: ${addOpacity(ratingColors[4], .25)};
      &.active {
        background-color: ${ratingColors[4]};
        color: var(--color-white);
      }
    }

    &:last-child .sv_q_rating_item_text {
      border: 0;
    }

    &.active {
      > .sv_q_rating_item_text {
        box-shadow: inset 0px 0px 0px 4px var(--color-primary-50);
      }
    }
  }

  /* Number */
  .sv_q_rating_item_text {
    text-align: center;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-40);
    font-weight: 500;
    display: block;
    border-right: 1px solid var(--color-neutral-10);
    padding: var(--space-squish-25);
    transition: box-shadow 150ms var(--bezier-a);
  }

  /* Text or explanation */
  .sv_q_rating_min_text,
  .sv_q_rating_max_text {
    display: block;
    position: absolute;
    bottom: -24px;
    pointer-events: none;
    font-size: var(--font-size-10);
    color: var(--color-neutral-40);
    left: 0;
    width: 50%;
  }

  .sv_q_rating_max_text {
    left: auto;
    right: 0;
    text-align: right;
  }

  /*
 * Component radiobutton or checkbox group
 */
  .sv_qcbc {
    border-radius: var(--border-radius-default);
    box-shadow: var(--box-shadow-20);

    &.sv_qbln {
      box-shadow: none;
    }
  }

  /*
 *
 *
 *
 *
 * Checkbox / radiobutton
 */
  .sv_q_checkbox,
  .sv_q_radiogroup {
    font-family: var(--font-family-heading);
    font-weight: 500;
    border-bottom: 1px solid var(--color-neutral-10);

    &:last-child {
      border-bottom: 0;
    }

    &.checked {
      background: rgba(240, 239, 243, 0.5);
    }
  }

  /* Label */
  .sv_q_checkbox_label,
  .sv_q_radiogroup_label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--space-30);
  }

  /* Input radio and checkbox */
  .sv_q_checkbox_control_item,
  .sv_q_radiogroup_control_item {
    display: none;
  }

  .sv_q_checkbox_control_item:checked + .checkbox-material,
  .sv_q_radiogroup_control_item:checked + .circle {
    background: var(--color-primary-50);
    border-color: var(--color-primary-50);
    box-shadow: 0px 0px 0px 4px var(--color-primary-20);

    &:before {
      opacity: 1;
    }
  }

  /* Input replacement for a checkbox */
  .sv_q_checkbox .checkbox-material {
    margin-right: 16px;
    height: 20px;
    width: 20px;
    border-radius: var(--border-radius-10);
    /* border-radius: 50%; */
    border: 2px solid var(--color-neutral-30);
    flex-shrink: 0;
    transition: box-shadow 100ms var(--bezier-a);

    &:before {
      opacity: 0;
      display: block;
      height: 13px;
      width: 13px;
      content: " ";
      margin: auto;
      color: var(--color-white);
      /* background-color: var(--color-white); */
      /* border-radius: 50%; */
      background-image: url(${tick});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  /* Input replacement for a checkbox */
  .sv_q_radiogroup .circle {
    margin-right: 16px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid var(--color-neutral-30);
    flex-shrink: 0;
    transition: box-shadow 100ms var(--bezier-a);

    &:before {
      opacity: 0;
      height: 8px;
      width: 8px;
      content: " ";
      margin: auto;
      color: var(--color-white);
      background-color: var(--color-white);
      border-radius: 50%;
    }
  }

  /* Text or answer */
  .sv_q_checkbox_control_label {
  }

  /*
 * Button bar
 */
  .sv_nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: var(--space-10);
    margin: var(--space-stack-40);
    overflow: hidden;

    > .sv_prev_btn {
      margin-right: 0 !important;
      grid-column: 1/2;
    }

    > .sv_next_btn,
    > .sv_complete_btn {
      grid-column: 3/4;
    }

    > .sv_hide_survey {
      grid-column: 2/3;
      display: flex;

      > .sv_pano_btn {
        background: url(${camera}) center/var(--space-40) no-repeat;
      }

      > .sv_map_btn {
        background: url(${map}) center/var(--space-40) no-repeat;
      }

      > .sv_pano_btn,
      > .sv_map_btn {
        display: none;
        flex-grow: 1;
        background-color: var(--color-neutral-10);

        &.visible {
          display: block;

          @media ${breakpoints[30]} {
            display: none;
          }
        }
      }
    }
  }

  .sv_q_imgsel {
    div {
      cursor: pointer;
      border-radius: var(--border-radius-10) !important;

      > img {
        pointer-events: auto !important;
        border-radius: var(--border-radius-10);
        max-width: calc(100vw - 50px);
      }
    }

    &.checked {
      div {
        background: var(--color-primary-50);
        color: white;
      }
    }
  }

  .sv-boolean__switch {
    background-color: white;
    border: 1px solid var(--color-neutral-10);
    box-shadow: var(--box-shadow-10);

    .sv-boolean__slider {
      background-color: var(--color-primary-50);
      margin-top: -1px;
    }
  }

  figure {
    width: 300px;
    height: 180px;
    display: inline-block;
    margin: 8px 0;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      margin: var(--space-stack-10);
      border-radius: var(--space-10);
      overflow: hidden;
    }
  }
`;

export const ShowMapButton = styled.div`
  width: 100%;
  height: 8rem;
  background: linear-gradient(rgba(31, 59, 230, 0.5), rgba(31, 59, 230, 0.5)), url(${MapButton});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 8rem;
  color: white;
  font-size: var(--font-size-50);
  text-align: center;
  border-radius: var(--border-radius-default);

  @media ${breakpoints[20]} {
    display: none;
  }
`;
