import React from "react";
import styled from "styled-components";
import { makeKeyDownHandler } from "../../utils/a11y-utils";
import { breakpoints } from "../../utils/styleUtils";
import Icon from "./Icon";

type ActionProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  onClick: () => void;
};

const Div = styled.div`
  padding: 9px 16px 10px 16px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--font-size-20);
  font-weight: 600;
  color: var(--color-neutral-70);
  cursor: pointer;

  &:hover {
    color: var(--color-primary-50);
  }

  /* Check if & + & really works */
  & + & {
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: var(--color-neutral-30);

      @media ${breakpoints[10]} {
        top: 8px;
        bottom: 8px;
        left: 0;
        height: unset;
        width: 1px;
      }
    }
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 12px;
`;

export default function Action({ children, icon, onClick }: ActionProps) {
  return (
    <Div onClick={onClick} tabIndex={0} onKeyDown={makeKeyDownHandler(onClick)}>
      <StyledIcon>{icon}</StyledIcon>
      <span>{children}</span>
    </Div>
  );
}
