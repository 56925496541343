import styled from "styled-components";
import Button from "../../shared/components/Button";
import { breakpoints } from "../../utils/styleUtils";
import { StyledLayoutSidebar } from "../Sidebar/SidebarStyles";

export const LandingContainer = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  overflow: hidden auto;
  position: relative;
  z-index: 70;
  padding: var(--space-default);
  background: white;

  &:after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 100%;
    background: var(--color-primary-50);
    opacity: 0.2;
    z-index: -1;
    transform: rotate(3deg);
  }

  @media ${breakpoints[10]} {
    grid-column: 1 / 4;

    ${StyledLayoutSidebar}.is-open+& {
      grid-column: 2 / 4;
    }
  }

  > div {
    max-width: 80ch;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${breakpoints[20]} {
      padding: 2rem 0;
    }
  }
`;

export const Entrances = styled.div`
  display: flex;
  width: 100%;
  gap: var(--space-default);

  & > ${Button} {
    text-transform: capitalize;
    display: block;
    width: 100%;
  }
`;
