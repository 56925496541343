import { useCopy } from "../hooks/useCopy";
import { AppState } from "../types";

export const shareView = (state: AppState, successMessage: string, failedMessage: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const copy = useCopy();

  if (!navigator.platform.includes("Win") && navigator.share) {
    navigator.share({
      url: window.location.href,
      text: state.description,
      title: state.name,
    }).then(
      () => alert(successMessage),
      () => alert(failedMessage)
    )
  } else {
    copy(window.location.href).then(
      () => alert(successMessage),
      () => alert(failedMessage)
    );
  }
};
