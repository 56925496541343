import { LayerGroup, WordpressArea, Area } from "../types";
import { decodeEntities } from "@wordpress/html-entities";
import { MarkerTypes } from "../shared/components/MarkerImages";

function transformWordpressArea(data: WordpressArea, layerGroups: LayerGroup[]): Area {
  const latitude = Number(data.acf.map_location.latitude);
  const longitude = Number(data.acf.map_location.longitude);
  const layers = data.acf.map_location.layers || [];
  const layerPois = data.acf.map_location.layer_pois || [];
  const coords = data.acf.bounding_box.split(/\s*,\s*/g).map(Number);
  const color = data.acf.map_location.color;
  const boundingBox: [[number, number], [number, number]] = [
    [coords[1], coords[0]],
    [coords[3], coords[2]],
  ];

  // Add to selected layers (all when empty)
  layerGroups
    .filter((l) => !layers.length || layers.includes(l.id))
    .forEach((layerGroup) => {
      upsert("slug", layerGroup.areas, {
        slug: data.slug || `draft-${data.id}`,
        latitude,
        longitude,
        color,
        title: decodeEntities(data.title.rendered),
        boundingBox,
      });
    });

  // Add pois to scenario's in the map
  layerGroups
    .filter((l) => layerPois.includes(l.id))
    .forEach((layerGroup) => {
      layerGroup.scenarios.forEach((scenario) => {
        upsert(["slug", "type"], scenario.pois, {
          slug: data.slug || `draft-${data.id}`,
          latitude,
          longitude,
          color,
          title: decodeEntities(data.title.rendered),
          type: MarkerTypes.Info,
        });
      });
    });

  return {
    id: data.id,
    title: decodeEntities(data.title.rendered),
    image: data.acf.image,
    boundingBox,
    content: data.content.rendered,
    excerpt: data.excerpt?.rendered,
    featuredMedia: data.featured_media,
    latitude,
    longitude,
    slug: data.slug || `draft-${data.id}`,
    color,
    status: data.status,
  };
}

export function transformWordpressAreas(data: WordpressArea[], layerGroups: LayerGroup[]) {
  return data.map((poi) => transformWordpressArea(poi, layerGroups));
}

const deepEqual = (a: any, b: any) => a === b || JSON.stringify(a) === JSON.stringify(b);

/** Add or replace an entry in the provided array, ensuring no item is added with the same `key` value as another item in the array */
export const upsert = <T, K extends keyof T>(key: K | K[], items: T[], item: T) => {
  const keyArray = Array.isArray(key) ? key : [key];
  let index = items.findIndex((currentItem) => keyArray.every(k => deepEqual(currentItem[k], item[k])));
  index = ~index ? index : items.length;
  items.splice(index, 1, item);
};
