import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuItemImage = styled.div`
  margin: var(--space-stack-default);

  img {
    width: 100%;
    height: 175px;
    display: block;
    object-fit: cover;
    border-radius: var(--border-radius-10);
  }
`;

export const MenuItemTitle = styled.span`
  display: block;
`;

export const MenuItemIntroduction = styled.span`
  display: block;
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
  color: var(--color-neutral-50);
  padding-top: var(--space-10);
  pointer-events: none;

  p {
    margin: 0;
  }
`;

export const StyledMenuLink = styled(Link)`
  border-bottom: 1px solid var(--color-neutral-10);
  display: block;
  line-height: var(--line-height-20);
  padding: 16px;
  text-decoration: none;
  color: var(--color-neutral-90);

  &:not(.phantom) {
    &:hover {
      color: var(--color-primary-50);
    }
  }
  
  &.active {
    background: var(--color-primary-20);
  }

  &.disable-hover-effects {
    &:hover {
      color: var(--color-neutral-90);
    }
  }

  &.flex {
    display: flex;

    > ${MenuItemImage} {
      flex: 0 0 58px;
      margin-right: 16px;
      margin-bottom: 0;

      > img {
        height: 58px;
      }
    }
  }

  &.phantom {
    background: var(--color-neutral-10);
    color: var(--color-neutral-30);
    cursor: default;
  }
`;

export const StyledResultLink = styled(Link)`
  display: block;
  padding: 8px 16px;
`;

export const StyledMenuItem = styled.div`
  border-bottom: 1px solid var(--color-neutral-10);
  display: block;
  line-height: var(--line-height-20);
  padding: 16px;
  text-decoration: none;
  color: var(--color-neutral-90);

  > div {
    cursor: pointer;

    &:hover {
      color: var(--color-primary-50);
    }
  }
  &.active {
    color: var(--color-primary-50);
    background: var(--color-neutral-10);
  }

  p.link {
    margin: 8px 0px 0px 0px;
  }
`;
