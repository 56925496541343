import { useMemo } from "react";
import { Source, Layer } from "react-map-gl";
import { useAppState } from "../../AppContext";
import { useRouteState } from "../../hooks/useRouteState";
import { MarkerTypes } from "../../shared/components/MarkerImages";
import { Faq, MapFaqData } from "../../types";
import { isMapFaq } from "../../utils/faqUtils";
import { generateLabelsGeoJson, poiLabelLayer } from "../../utils/poiUtils";
import { isMapTourPoint } from "../../utils/tourUtils";
import { MemoizedAreaMarker } from "./AreaMarker";
import { MemoizedMarker } from "./Marker";

type Props = {
  viewport: {
    longitude?: number;
    latitude?: number;
    zoom?: number;
  };
};

export const MapMarkers = ({ viewport }: Props) => {
  const { state } = useAppState();
  const routeState = useRouteState();

  let tourLayerSlug = "";
  let scenarioSlug = "";
  if (routeState.tourPointSlug) {
    const tourPoint = state.tourPoints.find((p) => p.slug === routeState.tourPointSlug);
    if (tourPoint && isMapTourPoint(tourPoint)) {
      tourLayerSlug = tourPoint?.data.layerGroup.slug;
      scenarioSlug = tourPoint?.scenarioSlug;
    }
  }
  const layerGroup = state.map.layerGroups.find(
    (l) => l.slug === (tourLayerSlug ? tourLayerSlug : routeState.activeLayerGroupSlug)
  );

  // Calculate pois
  const pois = useMemo(() => {
    return (
      (
        (scenarioSlug ? layerGroup?.scenarios.find((s) => s.slug === scenarioSlug) : undefined) ??
        layerGroup?.scenarios.find((s) => s.slug === routeState.activeScenarioSlug) ??
        layerGroup?.scenarios.find((s) => s.slug === state.defaultScenarioSlug) ??
        layerGroup?.scenarios[0]
      )?.pois ?? []
    );
  }, [
    scenarioSlug,
    routeState.activeScenarioSlug,
    state.defaultScenarioSlug,
    layerGroup?.scenarios,
  ]);

  const poiLabels = useMemo(() => generateLabelsGeoJson(pois), [pois]);

  const isParticipating =
    routeState.participationItemSlug ||
    document.getElementById("question-el") ||
    document.getElementById("survey-el");

  const faqMarkers = state.faqs.filter((faq) => {
    return (
      isMapFaq(faq) &&
      (faq.scenario.slug === routeState.activeScenarioSlug ||
        faq.scenario.slug === state.defaultScenarioSlug) &&
      faq.data.layerGroup.slug === routeState.activeLayerGroupSlug
    );
  }) as Faq<MapFaqData>[];

  return (
    <>
      {/** pois */}
      {((viewport.zoom && viewport.zoom >= state.mapConfig.areaInflectionPoint) || tourLayerSlug) &&
        pois
          ?.filter((p) => (isParticipating ? p.slug === routeState.participationItemSlug : true && (!p.phantom || state.showPhantomPanoLinks)))
          ?.sort((a, b) => a.longitude - b.longitude || a.latitude - b.latitude)
          .map((poi) => <MemoizedMarker key={poi.slug + poi.type} point={poi} />)}
      {/** areas */}
      {((viewport.zoom && viewport.zoom < state.mapConfig.areaInflectionPoint) || tourLayerSlug) &&
        !tourLayerSlug &&
        !isParticipating &&
        layerGroup?.areas
          .sort((a, b) => a.longitude - b.longitude || a.latitude - b.latitude)
          .map((area) => (
            <MemoizedAreaMarker
              minimumZoom={state.mapConfig.areaInflectionPoint}
              key={area.slug}
              area={area}
            />
          ))}
      {/** faqs */}
      {((viewport.zoom && viewport.zoom >= state.mapConfig.areaInflectionPoint) || tourLayerSlug) &&
        faqMarkers
          ?.filter((faq) => !isParticipating && isMapFaq(faq))
          ?.sort((a, b) => (a.data.location.longitude > b.data.location.longitude ? 1 : -1))
          .map((faq) => (
            <MemoizedMarker
              key={faq.slug + "-faq"}
              point={{
                title: "",
                longitude: faq.data.location.longitude,
                latitude: faq.data.location.latitude,
                slug: faq.slug,
                type: "faq",
              }}
            />
          ))}
      {!isParticipating && (
        // @ts-ignore
        <Source id="labels" type="geojson" data={poiLabels}>
          {/* Within a tour, always show the pois */}
          {/* @ts-ignore */}
          <Layer
            minzoom={tourLayerSlug ? 0 : state.mapConfig.areaInflectionPoint}
            {...poiLabelLayer(MarkerTypes.Pano)}
          />
          {state.showPhantomPanoLinks && (
            /* @ts-ignore */
            <Layer
              minzoom={tourLayerSlug ? 0 : state.mapConfig.areaInflectionPoint}
              {...poiLabelLayer(MarkerTypes.Pano, true)}
            />
          )}
          {state.showPoiLabels && (
            /* @ts-ignore */
            <Layer
              minzoom={tourLayerSlug ? 0 : state.mapConfig.areaInflectionPoint}
              {...poiLabelLayer(MarkerTypes.Info)}
            />
          )}
        </Source>
      )}
    </>
  );
};
