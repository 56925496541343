import { useMemo, useState } from "react";
import styled from "styled-components";
import { useSurveyState } from "../SurveyContext";
import { CreateTextAnswerModel, TextQuestionModel } from "./EndUserApi";

type Props = {
  question: TextQuestionModel;
};

const emailRegex =
  // eslint-disable-next-line no-control-regex
  /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
const urlRegex =
  /^(?:(?:(?:https?):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const phoneRegex = /^[+]?[(]?[0-9]{2,4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;

export const validateTextQuestion = (
  question: TextQuestionModel,
  answer?: CreateTextAnswerModel
) => {
  if (!answer) return !question.required;

  if ((question.required && !answer) || (question.required && !answer.text)) {
    return false;
  }
  if (question.maxLength && answer.text.length > question.maxLength) {
    return false;
  }
  if (question.subType === "email" && answer.text && emailRegex.test(answer.text) === false) {
    return false;
  }
  if (question.subType === "url" && answer.text && urlRegex.test(answer.text) === false) {
    return false;
  }
  if (question.subType === "phone" && answer.text && phoneRegex.test(answer.text) === false) {
    return false;
  }
  return true;
};

export const OpenQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();
  const [invalid, setInvalid] = useState(false);

  const answer = useMemo(() => {
    return surveyState.answers.find((a) => a.questionId === question.id) as CreateTextAnswerModel;
  }, [surveyState.answers, question.id]);

  const update = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let answer = surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateTextAnswerModel;
    if (!answer) {
      answer = {
        questionId: question.id,
        type: "TextAnswer",
        text: e.target.value,
      } as CreateTextAnswerModel;
    } else {
      answer.text = e.target.value.substring(0, question.maxLength || undefined);
    }
    if (question.maxLength && e.target.value.length > question.maxLength) {
      e.target.value = e.target.value.substring(0, question.maxLength);
    }
    setInvalid(false);
    setSurveyState((prevState) => ({
      ...prevState,
      answers: [...prevState.answers.filter((a) => a.questionId !== question.id), answer!],
    }));
  };

  const validateAnswer = () => {
    const valid = validateTextQuestion(question, answer);
    setInvalid(!valid);
  };

  return (
    <OpenQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}
      {question.subType === "textarea" && (
        <textarea
          rows={5}
          onChange={update}
          defaultValue={answer?.text}
          onBlur={validateAnswer}
          className={invalid ? "invalid" : ""}
        />
      )}
      {question.subType === "text" && (
        <input
          type="text"
          defaultValue={answer?.text}
          onChange={update}
          onBlur={validateAnswer}
          className={invalid ? "invalid" : ""}
        />
      )}
      {question.subType === "email" && (
        <input
          type="email"
          defaultValue={answer?.text}
          onChange={update}
          onBlur={validateAnswer}
          className={invalid ? "invalid" : ""}
        />
      )}
      {question.subType === "phone" && (
        <input
          type="tel"
          defaultValue={answer?.text}
          onChange={update}
          onBlur={validateAnswer}
          className={invalid ? "invalid" : ""}
        />
      )}
      {question.subType === "url" && (
        <input
          type="url"
          defaultValue={answer?.text}
          onChange={update}
          onBlur={validateAnswer}
          className={invalid ? "invalid" : ""}
        />
      )}
      {["textarea", "text"].includes(question.subType) && !!question.maxLength && (
        <CharacterLimitBox>
          {`(${answer?.text.length ?? 0} / ${question.maxLength})`}
        </CharacterLimitBox>
      )}
    </OpenQuestionContainer>
  );
};

const OpenQuestionContainer = styled.div`
  margin: 1rem 0;

  input,
  textarea {
    &.invalid {
      border-color: var(--color-error-50);
      box-shadow: 0 0 0 0.25rem var(--color-error-30);
    }
  }
`;

const CharacterLimitBox = styled.div`
  text-align: right;
  height: 0.5rem;
  margin: 0.5rem 0;
`;
