import { useCallback, useMemo } from "react";
import { HttpClient, RequestParams } from "../tim-survey/EndUserApi";
import {
  WordpressArea,
  WordpressConfig,
  WordpressFaq,
  WordpressLayerGroup,
  WordpressMenuItem,
  WordpressPage,
  WordpressPano,
  WordpressParticipationItem,
  WordpressPhasing,
  WordpressPoi,
  WordpressScenario,
  WordpressTour,
} from "../types";
import { useRestAuth } from "./useRestAuth";

class WordpressApi extends HttpClient {
  pages = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressPage[], any>({
        path: `/wp/v2/pages`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressPage>({
        path: `/wp/v2/pages/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  config = (params: RequestParams = {}) =>
    this.request<WordpressConfig>({
      path: `/acf/v3/options/options`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  pois = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressPoi[], any>({
        path: `/wp/v2/poi`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressPoi>({
        path: `/wp/v2/poi/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  panos = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressPano[], any>({
        path: `/wp/v2/pano`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressPano>({
        path: `/wp/v2/pano/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  phasing = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressPhasing[], any>({
        path: `/wp/v2/phasing`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressPhasing>({
        path: `/wp/v2/phasing/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  layerGroups = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressLayerGroup[], any>({
        path: `/wp/v2/layergroups`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressLayerGroup>({
        path: `/wp/v2/layergroups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  participation = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressParticipationItem[], any>({
        path: `/wp/v2/participation`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressParticipationItem>({
        path: `/wp/v2/participation/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scenarios = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressScenario[], any>({
        path: `/wp/v2/scenario`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressScenario>({
        path: `/wp/v2/scenario/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  areas = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressArea[], any>({
        path: `/wp/v2/areas`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressArea>({
        path: `/wp/v2/areas/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  tours = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressTour[], any>({
        path: `/wp/v2/tours`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressTour>({
        path: `/wp/v2/tours/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  faqs = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressFaq[], any>({
        path: `/wp/v2/faq`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressFaq>({
        path: `/wp/v2/faq/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  menu = (params: RequestParams = {}) =>
    this.request<WordpressMenuItem[]>({
      path: `/the_imagineers/v1/menu`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}

function useWordpressApi(baseUrl: string) {
  const restAuth = useRestAuth();

  const securityWorker = useCallback(() => {
    if (restAuth?.headers) {
      return {
        headers: restAuth.headers as HeadersInit,
      };
    } else if (restAuth?.auth) {
      return {
        headers: {
          Authorization: `Basic ${btoa(`${restAuth.auth.username}:${restAuth.auth.password}`)}`,
        },
      };
    }
    return {};
  }, [restAuth]);

  return useMemo(
    () =>
      new WordpressApi({
        baseUrl,
        securityWorker,
      }),
    [baseUrl, securityWorker]
  );
}

export default useWordpressApi;
