import { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { useInterfaceState } from "../../InterfaceContext";
import Button from "../../shared/components/Button";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { AppEntrance } from "../../types";
import { Entrances, LandingContainer } from "./LandingStyles";

const Landing = () => {
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const { state } = useAppState();
  const history = useHistory();

  const setEntrance = useCallback(
    (type: AppEntrance["type"]) => {
      const entrance = state.availableEntrances.find((e) => e.type === type);

      if (entrance) setInterfaceState((state) => ({ ...state, currentEntrance: entrance }));
    },
    [setInterfaceState, state.availableEntrances]
  );

  useEffect(() => {
    if (interfaceState.currentEntrance) {
      history.push(
        (interfaceState.currentEntrance.type === "map"
          ? "/map"
          : `/pano/${interfaceState.currentEntrance.pano.slug}`) + window.location.search
      );
    }
  }, [interfaceState.currentEntrance, history]);

  const entrances = useMemo(() => {
    if (state.availableEntrances.length === 1) {
      return (
        <Button onClick={() => setEntrance(state.availableEntrances[0].type)} className="primary">
          {<FormattedMessage id="landing.continue" defaultMessage={"Continue"} />}
        </Button>
      );
    }
    return state.availableEntrances.map((entrance, i) => {
      return (
        <Button key={i} onClick={() => setEntrance(entrance.type)} className="primary">
          {entrance.type}
        </Button>
      );
    });
  }, [state.availableEntrances, setEntrance]);

  useEffect(() => {
    if (!state.landingPage && interfaceState.currentEntrance) {
      history.replace(
        interfaceState.currentEntrance.type !== "map"
          ? `/pano/${interfaceState.currentEntrance.pano.slug}`
          : "/map"
      );
    }
  }, [state.landingPage, interfaceState.currentEntrance, history]);

  return state.landingPage ? (
    <LandingContainer>
      <div>
        <MemoizedWordpressContentWrapper content={state.landingPage.content} />
        <Entrances>{entrances}</Entrances>
      </div>
    </LandingContainer>
  ) : null;
};

export default Landing;
