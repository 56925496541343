import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useSurveyState } from "../SurveyContext";
import { CreateFileUploadAnswerModel, FileUploadQuestionModel } from "./EndUserApi";
import { makeKeyDownHandler } from "../utils/a11y-utils";

type Props = {
  question: FileUploadQuestionModel;
};

export const FileUploadQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();
  const [files, setFiles] = useState<FileList | null>(null);
  const [showMaxFilesError, setShowMaxFilesError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const answer = useMemo(() => {
    return surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateFileUploadAnswerModel;
  }, [surveyState.answers, question.id]);

  useEffect(() => {
    if (!answer) {
      const newAnswer: CreateFileUploadAnswerModel = {
        questionId: question.id,
        amountOfFiles: 0,
        type: "FileUploadAnswer",
      };
      setSurveyState((state) => ({
        ...state,
        answers: [...state.answers, newAnswer],
      }));
    }
  }, [answer, question.id, setSurveyState]);

  const clearFiles = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.files = null;
      const newAnswer: CreateFileUploadAnswerModel = {
        questionId: question.id,
        amountOfFiles: 0,
        type: "FileUploadAnswer",
      };
      setSurveyState((state) => ({
        ...state,
        answers: [...state.answers.filter((a) => a.questionId !== question.id), newAnswer],
      }));
      setFiles(null);
    }
  }, [question.id, setSurveyState]);

  const handleFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowMaxFilesError(false);
      if (e.target.files === null) {
        answer.amountOfFiles = 0;
        return;
      }
      if (e.target.files.length > question.maxAmount) {
        clearFiles();
        setShowMaxFilesError(true);
        return;
      }
      answer.amountOfFiles = e.target.files.length;
      setFiles(e.target.files);
      setSurveyState((state) => ({
        ...state,
        files: {
          ...state.files,
          [question.id]: e.target.files,
        },
      }));
    },
    [answer, clearFiles, question.id, question.maxAmount, setSurveyState]
  );

  return (
    <FileUploadQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}
      <ButtonContainer>
        <InputLabel className={files !== null && files.length >= 1 ? "disabled" : ""}>
          <input
            type="file"
            accept={question.allowedTypes.join(",")}
            multiple={question.maxAmount > 1}
            disabled={files !== null && files.length >= 1}
            onChange={handleFiles}
            ref={inputRef}
          />
          Selecteer bestand
        </InputLabel>
        {files && files.length > 0 && (
          <ClearFilesLink
            onClick={clearFiles}
            role="button"
            onKeyDown={makeKeyDownHandler(clearFiles)}
          >
            Bestanden wissen
          </ClearFilesLink>
        )}
      </ButtonContainer>
      {showMaxFilesError && (
        <ErrorDisplay>Selecteer maximaal {question.maxAmount} bestanden.</ErrorDisplay>
      )}
      {files &&
        Array.from(files).map((f) => {
          return <FileItem key={f.name}>{f.name}</FileItem>;
        })}
    </FileUploadQuestionContainer>
  );
};

const FileUploadQuestionContainer = styled.div`
  margin: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`;

const InputLabel = styled.label`
  > input {
    display: none;
  }

  border: 0;
  border-radius: var(--border-radius-50);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-body);
  font-size: var(--font-size-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-30);
  line-height: var(--line-height-default);
  margin: 0;
  padding: var(--space-squish-25);
  text-decoration: none;
  background: var(--color-primary-50);
  color: var(--color-white);

  &.disabled {
    background: var(--color-neutral-30);
    color: var(--color-neutral-50);
    pointer-events: none;
  }

  &:hover {
    background: var(--color-primary-70);
    color: var(--color-white);
  }
`;

const ErrorDisplay = styled.p`
  color: var(--color-error-50);
  font-weight: bold;
`;

const FileItem = styled.div`
  margin: 0.5rem 0 1rem;
`;

const ClearFilesLink = styled.div`
  cursor: pointer;
`;
