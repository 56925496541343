export enum ThreeMessageCommand {
  Init = "init",
  Pano = "pano",
  Poi = "poi",
  Map = "map",
  Close = "close",
}

export interface ThreeMessage {
  transaction: string;
  command: ThreeMessageCommand;
  data: any;
}