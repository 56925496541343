import { defineMessages } from "react-intl";

export const messages = defineMessages({
  PRODUCT_NAME: {
    id: "global.PRODUCT_NAME",
    defaultMessage: "Stakeholder Engagement Platform",
    description: "The localized name of the product.",
  },
  backButton: {
    id: "global.back-button",
    defaultMessage: "Back",
    description: "The label for a 'back' button.",
  },
  continueButton: {
    id: "global.continue-button",
    defaultMessage: "Continue",
    description: "The label for a 'continue' button.",
  },
  finishButton: {
    id: "global.finish-button",
    defaultMessage: "Finish",
    description: "The label for a 'finish' button.",
  },
  previousButton: {
    id: "global.previous-button",
    defaultMessage: "Previous",
    description: "The label for a 'previous' button.",
  },
  nextButton: {
    id: "global.next-button",
    defaultMessage: "Next",
    description: "The label for a 'next' button.",
  },
  startButton: {
    id: "global.start-button",
    defaultMessage: "Start",
    description: "The label for a 'start' button.",
  },
  closeButton: {
    id: "global.close-button",
    defaultMessage: "Close",
    description: "The label for a 'close' button.",
  },
  homeButton: {
    id: "global.home-button",
    defaultMessage: "Home",
    description: "The label for a 'home' button.",
  },
  shareButton: {
    id: "global.share-button",
    defaultMessage: "Share",
    description: "The label for a 'share' button.",
  },
  askQuestionButton: {
    id: "global.ask-question-button",
    defaultMessage: "Ask a question",
    description: "The label for a 'ask question' button.",
  },
  loading: {
    id: "global.loading",
    defaultMessage: "Loading...",
    description: "Textual loading indicator.",
  },
  readMore: {
    id: "global.read-more",
    defaultMessage: "Read more",
    description: "Call to action to show there's more content to read for this subject.",
  },
  cancelButton: {
    id: "global.cancel-button",
    defaultMessage: "Cancel",
    description: "The label for a 'cancel' button.",
  },
  menuButton: {
    id: "global.menu-button",
    defaultMessage: "Menu",
    description: "The label for the 'menu' button.",
  },
  copyShareLinkSuccessMessage: {
    id: "global.alert.copy-share-success",
    defaultMessage: "The URL has been copied to the clipboard.",
    description: "Alert message to show when copying a share-link has succeeded.",
  },
  copyShareLinkFailedMessage: {
    id: "global.alert.copy-share-failed",
    defaultMessage:
      "The URL could not be copied, please copy the URL from the address-bar of your device in order to share.",
    description: "Alert message to show when copying a share-link has failed",
  },
  zoomIn: {
    id: "global.zoom-in",
    defaultMessage: "Zoom in",
    description: "The label for the 'zoom in' button.",
  },
  zoomOut: {
    id: "global.zoom-out",
    defaultMessage: "Zoom out",
    description: "The label for the 'zoom out' button.",
  },
});

export const abbreviations = defineMessages({
  january: {
    id: "global.abbr.january",
    defaultMessage: "Jan",
    description: "Abbreviation for 'January'",
  },
  februari: {
    id: "global.abbr.february",
    defaultMessage: "Feb",
    description: "Abbreviation for 'Februari'",
  },
  march: {
    id: "global.abbr.march",
    defaultMessage: "Mar",
    description: "Abbreviation for 'March'",
  },
  april: {
    id: "global.abbr.april",
    defaultMessage: "Apr",
    description: "Abbreviation for 'April'",
  },
  may: {
    id: "global.abbr.may",
    defaultMessage: "May",
    description: "Abbreviation for 'May'",
  },
  june: {
    id: "global.abbr.june",
    defaultMessage: "Jun",
    description: "Abbreviation for 'June'",
  },
  juli: {
    id: "global.abbr.july",
    defaultMessage: "Jul",
    description: "Abbreviation for 'Juli'",
  },
  august: {
    id: "global.abbr.august",
    defaultMessage: "Aug",
    description: "Abbreviation for 'August'",
  },
  september: {
    id: "global.abbr.september",
    defaultMessage: "Sep",
    description: "Abbreviation for 'September'",
  },
  oktober: {
    id: "global.abbr.oktober",
    defaultMessage: "Okt",
    description: "Abbreviation for 'Oktober'",
  },
  november: {
    id: "global.abbr.november",
    defaultMessage: "Nov",
    description: "Abbreviation for 'November'",
  },
  december: {
    id: "global.abbr.december",
    defaultMessage: "Dec",
    description: "Abbreviation for 'December'",
  },
  threeDimensional: {
    id: "global.abbr.3d",
    defaultMessage: "3D",
    description: "Abbreviation for '3-dimensional'",
  },
  PRODUCT_NAME: {
    id: "global.abbr.PRODUCT_NAME",
    defaultMessage: "SEP",
    description: "Abbreviation for 'Stakeholder Engagement Platform'",
  },
});
