import React, { useCallback, useMemo, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useAppState } from "../../AppContext";
import { Capability, useCapability } from "../../hooks/useCapability";
import { useCopy } from "../../hooks/useCopy";
import { useUpdateStateUrl } from "../../hooks/useUpdateStateUrl";
import { Modal, ModalProps } from "../../shared/components/Modal";

export const ShareModal = (props: ModalProps) => {
  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedHtml, setCopiedHtml] = useState(false);
  const [linkTimeout, setLinkTimeout] = useState<number | null>(null);
  const [htmlTimeout, setHtmlTimeout] = useState<number | null>(null);
  const { state } = useAppState();
  const canReadDraft = useCapability(Capability.ReadDraft);
  const updateStateUrl = useUpdateStateUrl();
  const intl = useIntl();

  const linkRef = useRef<HTMLInputElement>(null);
  const copyLink = useCopy(linkRef);
  const embedRef = useRef<HTMLTextAreaElement>(null);
  const copyEmbed = useCopy(embedRef);

  const allowEmbedding = useMemo(() => {
    if (!state.enableEmbed) return false;

    if (!state.enableEmbedAncestors || state.enableEmbedAncestors.match(/^\s*https?:\/\/\*\s*$/)) {
      return true;
    }

    return canReadDraft;
  }, [canReadDraft, state.enableEmbed, state.enableEmbedAncestors]);

  const iframeString = useMemo(
    () =>
      `<iframe src="${
        (window.location.origin + updateStateUrl({ embed: true }, undefined, true)).replace(window.location.search, "")
      }" width="1000px" height="700px" seamless allowfullscreen />`,
    [updateStateUrl]
  );

  const copyShareLink = useCallback(async () => {
    await copyLink();

    setCopiedLink(true);
    if (linkTimeout) clearTimeout(linkTimeout);
    setLinkTimeout(
      window.setTimeout(() => {
        setCopiedLink(false);
      }, 3000)
    );
  }, [linkTimeout, setCopiedLink, setLinkTimeout, copyLink]);

  const copyEmbedHtml = useCallback(async () => {
    await copyEmbed();

    setCopiedHtml(true);
    if (htmlTimeout) clearTimeout(htmlTimeout);
    setHtmlTimeout(
      window.setTimeout(() => {
        setCopiedHtml(false);
      }, 3000)
    );
  }, [htmlTimeout, setCopiedHtml, setHtmlTimeout, copyEmbed]);

  const copyLabel = intl.formatMessage({
    id: "share-model.copy-label",
    defaultMessage: "Copy",
    description:
      "Label for a 'copy' button in the share modal when the associated field has not been copied.",
  });
  const copiedLabel = intl.formatMessage({
    id: "share-model.copied-label",
    defaultMessage: "Copied",
    description:
      "Label for a 'copy' button in the share modal after the associated field has been copied.",
  });

  return (
    <Modal {...props}>
      <FormattedMessage
        tagName="h2"
        id="share-modal.title"
        defaultMessage="Share this platform"
        description="Title for the share modal."
      />
      <p>
        <FormattedMessage
          id="share-modal.link-intro"
          defaultMessage="Would you like acquaintaces to view this platform, share the link to the full platform."
          description="Introductory text for sharing the platform link."
        />
        {allowEmbedding && (
          <>
            <br />
            <FormattedMessage
              id="share-modal.embed-intro"
              defaultMessage="Or choose to embed this platform into an online page, like a news post."
              description="Introductory text for embedding the platform."
            />
          </>
        )}
      </p>
      <FormattedMessage
        tagName="h3"
        id="share-modal.share-link-header"
        defaultMessage="Share a link"
        description="Call to action header displayed directly above the share-link."
      />
      <CopyWrapper onClick={copyShareLink} afterContent={copiedLink ? copiedLabel : copyLabel} success={copiedLink}>
        <input
          type="text"
          readOnly
          ref={linkRef}
          value={window.location.href.replace(window.location.search, "")}
          id="share-link"
          onClick={(e) => e.stopPropagation()}
        />
      </CopyWrapper>
      {allowEmbedding && (
        <>
          <FormattedMessage
            tagName="h3"
            id="share-modal.embed-link-header"
            defaultMessage="Embed the platform"
            description="Call to action header displayed directly above the embed-link."
          />
          <CopyWrapper onClick={copyEmbedHtml} afterContent={copiedHtml ? copiedLabel : copyLabel} success={copiedHtml}>
            <textarea
              readOnly
              ref={embedRef}
              value={iframeString}
              rows={6}
              id="share-embed"
              onClick={(e) => e.stopPropagation()}
            />
          </CopyWrapper>
        </>
      )}
    </Modal>
  );
};

interface CopyWrapperProps {
  afterContent: string;
  success?: boolean;
}

const CopyWrapper = styled.div<CopyWrapperProps>`
  width: auto;
  height: auto;
  position: relative;
  pointer-events: none;

  input[type="text"],
  textarea {
    padding-right: 90px;
    pointer-events: all;
  }

  &:after {
    display: block;
    position: absolute;
    content: "${({afterContent}) => afterContent}";
    color: var(${({success = false}) => success ? "--color-success-50" : "--color-primary-50"});
    right: var(--space-25);
    top: var(--space-30);
    pointer-events: all;
    text-align: right;
    cursor: pointer;
  }

  + h3 {
    margin-top: var(--space-25);
  }
`;
