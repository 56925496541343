import { useCallback, useMemo, useState } from "react";
import { findAnswer, isLocationQuestion, LocalPinModel, useSurveyState } from "../SurveyContext";
import { MapRef, Marker as MapMarker, MarkerDragEvent } from "react-map-gl";
import { MarkerIconStyles } from "../components/Map/MapStyles";
import { DefaultMarker } from "../components/Map/DefaultMarker";
import { GeoLocationPinModel } from "./EndUserApi";
import { useRouteState } from "../hooks/useRouteState";
import { useAppState } from "../AppContext";
import { SurveyWarningModal } from "../components/Modals/SurveyWarningModal";
import { RestrictionLayerType } from "./enums";

type Props = {
  map: MapRef;
};

export const SurveyMapMarkers = ({ map }: Props) => {
  const { surveyState, openModalFn, setSurveyState } = useSurveyState();
  const { state } = useAppState();
  const [surveyWarningModalMessage, setSurveyWarningModalMessage] = useState<string | undefined>(
    undefined
  );

  const closeSurveyWarningModal = useCallback(() => setSurveyWarningModalMessage(undefined), []);

  const getLayerIdBySlug = useCallback(
    (slug?: string) => state.map.layerGroups.find((layer: any) => layer.slug === slug)?.id ?? 0,
    [state.map?.layerGroups]
  );

  const getScenarioIdBySlug = useCallback(
    (slug: string) => state.scenarios.find((scenario: any) => scenario.slug === slug)?.id ?? 0,
    [state.scenarios]
  );

  const { activeLayerGroupSlug, activeScenarioSlug } = useRouteState();

  const activePage = useMemo(
    () => surveyState.survey?.pages.find((p) => p.id === surveyState.activePageId),
    [surveyState.activePageId, surveyState.survey?.pages]
  );

  const activeLocationQuestions = useMemo(
    () => activePage?.elements.filter(isLocationQuestion) ?? [],
    [activePage]
  );

  const activeAnswers = useMemo(
    () => activeLocationQuestions.flatMap((q) => surveyState.answers.filter(findAnswer(q))),
    [activeLocationQuestions, surveyState.answers]
  );

  const markers = useMemo(
    () =>
      activeAnswers
        .flatMap((a) => a.pins.filter((p): p is GeoLocationPinModel => p.type === "GeoLocation"))
        .filter(
          (p) =>
            p.layerId === getLayerIdBySlug(activeLayerGroupSlug) &&
            p.scenarioId === getScenarioIdBySlug(activeScenarioSlug)
        ),
    [activeAnswers, activeLayerGroupSlug, getLayerIdBySlug, activeScenarioSlug, getScenarioIdBySlug]
  );

  const getPinColor = useCallback(
    (pinId: number) => {
      if (!activeLocationQuestions) return;
      const question = activeLocationQuestions.find((q) => q.pins.some((p) => p.id === pinId));
      const pin = question?.pins.find((p) => p.id === pinId);
      return pin?.color ?? undefined;
    },
    [activeLocationQuestions]
  );

  const onMarkerDragEnd = useCallback(
    (event: MarkerDragEvent, answerId: number) => {
      const answer = activeAnswers.find((a) =>
        a.pins.find((p) => (p as LocalPinModel).id === answerId)
      );
      const question = activeLocationQuestions?.find((e) => {
        return answer?.questionId === e.id;
      });

      if (question?.restrictions[0]) {
        const features = map
          .getMap()
          .queryRenderedFeatures(map.project([event.lngLat.lng, event.lngLat.lat]));
        const onRestrictedLayer = features?.some((f) => {
          return question.restrictions[0].cluster[0].split(",").some((c) => {
            return f.layer?.id === c;
          });
        });

        if (
          (!onRestrictedLayer &&
            question.restrictions[0].restrictionType === RestrictionLayerType.Mandatory) ||
          (onRestrictedLayer &&
            question.restrictions[0].restrictionType === RestrictionLayerType.Restricted)
        ) {
          setSurveyWarningModalMessage(question.restrictions[0].message);
          // used to force a rerender of the map, moving the pin back to its original position
          map.getMap().setCenter(map.getMap().getCenter());
          return;
        }
      }

      const pin = answer?.pins.find((p) => (p as LocalPinModel).id === answerId);
      if (!pin) return;
      pin.latitude = event.lngLat.lat;
      pin.longitude = event.lngLat.lng;
      setSurveyState({
        ...surveyState,
        answers: surveyState.answers.map((a) => (a.questionId === answer?.questionId ? answer : a)),
      });
    },
    [activeAnswers, activeLocationQuestions, map, setSurveyState, surveyState]
  );

  return (
    <>
      {markers &&
        markers.map((m) => (
          <MapMarker
            latitude={m.latitude}
            longitude={m.longitude}
            key={(m as LocalPinModel).id}
            offset={[5, -10]}
            onClick={() => openModalFn((m as LocalPinModel).id)}
            draggable
            onDragEnd={(event) => onMarkerDragEnd(event, (m as LocalPinModel).id)}
          >
            <div>
              <MarkerIconStyles className="">
                <DefaultMarker color={getPinColor(m.pinDefinition)} />
              </MarkerIconStyles>
            </div>
          </MapMarker>
        ))}
      <SurveyWarningModal
        open={!!surveyWarningModalMessage}
        onClose={closeSurveyWarningModal}
        message={surveyWarningModalMessage}
      />
    </>
  );
};
