import { useMemo } from "react";
import styled from "styled-components";
import { useSurveyState } from "../SurveyContext";
import { RatingQuestionModel, CreateRatingAnswerModel } from "./EndUserApi";

type Props = {
  question: RatingQuestionModel;
};

export const RatingQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const answer = useMemo(() => {
    return surveyState.answers.find((a) => a.questionId === question.id) as CreateRatingAnswerModel;
  }, [surveyState.answers, question.id]);

  const update = (e: React.ChangeEvent<HTMLInputElement>) => {
    let answer = surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateRatingAnswerModel;
    if (!answer) {
      answer = {
        questionId: question.id,
        type: "RatingAnswer",
        rating: Number(e.target.value),
      } as CreateRatingAnswerModel;
    } else {
      answer.rating = Number(e.target.value);
    }
    setSurveyState((prevState) => ({
      ...prevState,
      answers: [...prevState.answers.filter((a) => a.questionId !== question.id), answer!],
    }));
  };

  return (
    <RatingQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}

      <RatingWrapper className={answer?.rating !== undefined ? "answered" : ""}>
        {question.options.map((option) => (
          <label
            htmlFor={`question-${question.id}-${option.value}`}
            className={answer?.rating === option.value ? "active" : ""}
          >
            <input
              type="radio"
              onChange={update}
              id={`question-${question.id}-${option.value}`}
              value={option.value}
              checked={answer?.rating === option.value}
            />
            {option.value + 1}
          </label>
        ))}
      </RatingWrapper>
      <LabelWrapper>
        <div>{question.options[0].label}</div>
        <div>{question.options.at(-1)!.label}</div>
      </LabelWrapper>
    </RatingQuestionContainer>
  );
};

const RatingQuestionContainer = styled.div`
  margin: 1rem 0;
`;

const RatingWrapper = styled.div`
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow-20);
  display: inline-flex;
  margin-bottom: var(--space-20);
  position: relative;
  width: 100%;

  &.answered {
    > label {
      border-color: var(--color-primary-50);

      &:last-child {
        border-right-color: var(--color-primary-50);
      }
    }
  }

  input[type="radio"] {
    display: none;
  }

  label {
    flex: 1;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-size: var(--font-size-14);
    border: 2px solid var(--color-neutral-30);
    border-right: none;
    text-align: center;
    line-height: 2.75rem;
    height: 3rem;
    font-weight: bold;
    color: var(--color-neutral-50);

    &:hover:not(.active) {
      background-color: var(--color-primary-20);
    }

    &.active {
      background-color: var(--color-primary-50);
      color: white;
    }

    &:first-child {
      border-radius: var(--border-radius-10) 0 0 var(--border-radius-10);
    }

    &:last-child {
      border-radius: 0 var(--border-radius-10) var(--border-radius-10) 0;
      border-right: 2px solid var(--color-neutral-30);
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-40);
  width: 100%;
`;
