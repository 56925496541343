import { useMemo } from "react";
import styled from "styled-components";
import { useSurveyState } from "../SurveyContext";
import { ChoiceQuestionModel, CreateChoiceAnswerModel } from "./EndUserApi";

type Props = {
  question: ChoiceQuestionModel;
};

export const ChoiceQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const answer = useMemo(() => {
    return surveyState.answers.find((a) => a.questionId === question.id) as CreateChoiceAnswerModel;
  }, [surveyState.answers, question.id]);

  const updateCheckbox = (value: string) => {
    let answer = surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateChoiceAnswerModel;
    if (!answer) {
      answer = {
        questionId: question.id,
        type: "ChoiceAnswer",
        choices: [{ value, selected: true }],
      } as CreateChoiceAnswerModel;
    } else {
      if (answer.choices?.find((c) => c.value === value)) {
        answer.choices = answer.choices.filter((c) => c.value !== value);
      } else {
        answer.choices = [...answer.choices, { value, selected: true }];
      }
    }
    setSurveyState((prevState) => ({
      ...prevState,
      answers: [...prevState.answers.filter((a) => a.questionId !== question.id), answer!],
    }));
  };

  const updateRadio = (value: string) => {
    let answer = surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateChoiceAnswerModel;
    if (!answer) {
      answer = {
        questionId: question.id,
        type: "ChoiceAnswer",
        choices: [{ value, selected: true }],
      } as CreateChoiceAnswerModel;
    } else {
      answer.choices = [{ value, selected: true }];
    }
    setSurveyState((prevState) => ({
      ...prevState,
      answers: [...prevState.answers.filter((a) => a.questionId !== question.id), answer!],
    }));
  };

  return (
    <ChoiceQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}
      {question.allowMultiple &&
        question.options.map((choice) => {
          return (
            <InputLabel htmlFor={`key-${question.id}-${choice.value}`} key={choice.value}>
              <input
                type="checkbox"
                onChange={() => updateCheckbox(choice.value)}
                id={`key-${question.id}-${choice.value}`}
                checked={answer?.choices
                  ?.filter((c) => c.selected)
                  .map((c) => c.value)
                  .includes(choice.value)}
              />
              {choice.label}
            </InputLabel>
          );
        })}
      {!question.allowMultiple &&
        question.options.map((choice) => {
          return (
            <InputLabel htmlFor={`key-${question.id}-${choice.value}`} key={choice.value}>
              <input
                type="radio"
                name={question.id.toString()}
                onChange={() => updateRadio(choice.value)}
                id={`key-${question.id}-${choice.value}`}
                checked={answer?.choices
                  ?.filter((c) => c.selected)
                  .map((c) => c.value)
                  .includes(choice.value)}
              />
              {choice.label}
            </InputLabel>
          );
        })}
    </ChoiceQuestionContainer>
  );
};

const ChoiceQuestionContainer = styled.div`
  margin: 1rem 0;
`;

const InputLabel = styled.label`
  display: flex;
  width: fit-content;
  padding-right: var(--space-30);
  margin-bottom: 8px;
  align-items: center;
  background-color: var(--color-neutral-10);
  border-radius: var(--border-radius-10);
  color: black;

  > input {
    margin: var(--space-20);
  }
`;
