import { useInterfaceState } from "../InterfaceContext";
import { useGeneratePath } from "./useGeneratePath";
import { useRouteState } from "./useRouteState";

export interface SceneHistory {
  lastScene?: string;
  lastPano?: string;
  lastMapOpen?: boolean;
}

export function useSceneUrl() {
  const generatePath = useGeneratePath();
  const { interfaceState } = useInterfaceState();
  const { panoSlug } = useRouteState(true);

  const sceneUrl = (sceneSlug: string | false) => {
    return generatePath({
      sceneSlug: sceneSlug || undefined,
      map: interfaceState.currentEntrance.type === "map" ? "map" : undefined,
      panoSlug: interfaceState.currentEntrance.type !== "map" ? (panoSlug ?? interfaceState.currentEntrance.pano.slug) : undefined,
      poiSlug : undefined,
      contentSlug : undefined,
      faqSlug : undefined,
      tourSlug : undefined,
      tourPointSlug : undefined,
      projectSlug : undefined,
      phaseSlug : undefined,
    }, false, false);
  };

  return sceneUrl;
}