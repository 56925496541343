import { StyleOverrides, WordpressConfig } from "../types";

export const breakpoints = {
  10: "(min-width: 600px)",
  20: "(min-width: 750px)",
  30: "(min-width: 1000px)",
  40: "(min-width: 1200px)",
  map: "(min-width: 640px)",
};

export const sidebarWidth = 365;

const validHexRegex = /^#([\da-f]{6}|[\da-f]{3})$/i;
const convertHexRegex = /([\da-f]{1,2})([\da-f]{1,2})([\da-f]{1,2})/i;
const rgbaRegex = /rgba?\(((?:\s*\d*\.?\d+\s*,\s*){2,3})(?:\d*\.?\d+\s*)?\)/;

export const addOpacity = (color: string, opacity: number) => {
  switch (true) {
    case validHexRegex.test(color):
      return `rgba(${color
        .match(convertHexRegex)!
        .slice(1)
        .map((channel: string) =>
          parseInt(channel.length === 1 ? channel + channel : channel, 16)
        )},${opacity})`;

    case rgbaRegex.test(color):
      return color.replace(rgbaRegex, `rgba($1${opacity})`);
  }

  return;
};

export const styleDefaults: StyleOverrides = {
  "color-primary-20": "#D2D8FA",
  "color-primary-30": "#8B9BF9",
  "color-primary-50": "#1F3BE6",
  "color-primary-70": "#11228A",
  "color-success-30": "#97E7B7",
  "color-success-50": "#19B457",
  "color-success-70": "#008636",
  "color-error-30": "#FFC1BA",
  "color-error-50": "#FE5441",
  "color-error-70": "#BA1B09",
  "color-informative-30": "#4ED4E9",
  "color-informative-50": "#17A2B8",
  "color-informative-70": "#0C545F",
  "font-family-body":
    "open-sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, sans-serif",
  "font-family-heading":
    "'Proxima Nova', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, sans-serif",
};

export const transformWordpressStyles = (config?: WordpressConfig): StyleOverrides => ({
  ...styleDefaults,
  ...pickTruthy<Partial<StyleOverrides>>(config?.acf.primary_color ?? {}),
  ...pickTruthy<Partial<StyleOverrides>>(config?.acf.success_color ?? {}),
  ...pickTruthy<Partial<StyleOverrides>>(config?.acf.error_color ?? {}),
  ...pickTruthy<Partial<StyleOverrides>>(config?.acf.informative_color ?? {}),
  ...transformFonts(config),
});

const transformFonts = (config?: WordpressConfig) => {
  const overrides: Partial<StyleOverrides> = {};

  if (!config) return overrides;

  const body = config.acf["font-family-body"];
  const heading = config.acf["font-family-heading"];

  if (body) {
    overrides["font-family-body"] =
      body.split(",").filter(Boolean).join(", ") + ", " + styleDefaults["font-family-body"];
  }

  if (heading) {
    overrides["font-family-heading"] =
      heading.split(",").filter(Boolean).join(", ") + ", " + styleDefaults["font-family-heading"];
  }

  return overrides;
};

const pickTruthy = <T extends object>(style: T): T =>
  (Object.entries(style) as [keyof T, T[keyof T]][]).reduce<T>((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {} as T);
