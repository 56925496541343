import ReactMapGL, { MapRef } from "react-map-gl";
import { useAppState } from "../../AppContext";
import { useRouteState } from "../../hooks/useRouteState";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import { Marker as MapMarker } from "react-map-gl";
import { MarkerIconStyles } from "../Map/MapStyles";
import { RotationMarker } from "../Map/RotationMarker";
import { usePanoState } from "../../hooks/usePanoState";
import { Link } from "react-router-dom";
import { useMapUrl } from "../../hooks/useMapUrl";
import { useInterfaceState } from "../../InterfaceContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pano } from "../../types";
import WebMercatorViewport from "viewport-mercator-project";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export function MiniMap() {
  const { state } = useAppState();
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const [loaded, setLoaded] = useState(false);
  const [movedInitial, setMovedInitial] = useState(false);
  const panoState = usePanoState();
  const routeState = useRouteState();
  const mapRef = useRef<MapRef>(null!);
  const mapUrl = useMapUrl();

  const pano = useMemo(
    () => state.panos.find((p) => p.slug === routeState.panoSlug),
    [routeState.panoSlug, state.panos]
  );

  const viewport = useMemo(() => {
    if (pano?.latitude !== undefined && pano.longitude !== undefined && !movedInitial) {
      return new WebMercatorViewport({
        latitude: pano.latitude,
        longitude: pano.longitude,
        zoom: 13,
        width: 200,
        height: 140,
      });
    }
  }, [movedInitial, pano?.latitude, pano?.longitude]);

  const viewDirection = useMemo(
    () => (panoState?.hlookat === undefined ? pano?.yaw : panoState.hlookat),
    [panoState?.hlookat, pano?.yaw]
  );

  const direction = useMemo(() => {
    if (pano?.north !== undefined && viewDirection !== undefined) return viewDirection - pano.north;
  }, [pano?.north, viewDirection]);

  const onMinimapClick = useCallback(() => {
    if (interfaceState.currentEntrance.type !== "map") {
      // delay to keep sidebar open state in panomap component
      requestAnimationFrame(() =>
        setInterfaceState((currentState) => ({
          ...currentState,
          sidebarOpen: false,
        }))
      );
    }
  }, [interfaceState.currentEntrance.type, setInterfaceState]);

  useEffect(() => {
    if (!pano?.latitude || !pano?.longitude) return;
    if (loaded && !movedInitial && mapRef.current) {
      mapRef.current?.setCenter([pano.longitude, pano.latitude]);
      setMovedInitial(true);
      return;
    }
    mapRef.current?.flyTo({ center: [pano.longitude, pano.latitude], duration: 2000 });
  }, [pano?.latitude, pano?.longitude, loaded, movedInitial]);

  const geoReferencedPano = pano as Pano & { latitude: number; longitude: number };

  return (
    <MiniMapContainer to={mapUrl()} onClick={onMinimapClick} data-html2canvas-ignore>
      <ReactMapGL
        {...viewport}
        ref={mapRef}
        onLoad={() => setLoaded(true)}
        mapboxAccessToken={state.mapConfig.accessToken}
        mapStyle={state.mapConfig.style}
        cursor={"pointer"}
        style={{ pointerEvents: "none" }}
      >
        {loaded && (
          <MapMarker latitude={geoReferencedPano.latitude} longitude={geoReferencedPano.longitude}>
            <div>
              <MarkerIconStyles>
                <RotationMarker
                  type={
                    ["auto", ""].includes(geoReferencedPano.icon)
                      ? "pano"
                      : (geoReferencedPano.icon as Exclude<Pano["icon"], "auto">)
                  }
                  direction={direction}
                  height={geoReferencedPano.type}
                  selected={false}
                />
              </MarkerIconStyles>
            </div>
          </MapMarker>
        )}
      </ReactMapGL>
    </MiniMapContainer>
  );
}

const MiniMapContainer = styled(Link)`
  width: 200px;
  height: 140px;
  position: absolute;
  margin-bottom: var(--space-default);
  bottom: 0;
  right: var(--space-default);
  border-radius: var(--space-10);
  overflow: hidden;
  z-index: 40;

  .show-video-controls &,
  .show-audio-timeline & {
    bottom: 48px;
  }

  .mapboxgl-ctrl-bottom-right,
  .mapboxgl-ctrl-bottom-left {
    display: none;
  }
`;
