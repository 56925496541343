import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { findAnswer, useSurveyState } from "../SurveyContext";
import { CreateTrackingAnswerModel, TrackingQuestionModel } from "./EndUserApi";
import { TrackingType } from "./enums";

type Props = {
  question: TrackingQuestionModel;
};

export const TrackingQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const answer = useMemo<CreateTrackingAnswerModel>(() => {
    return (
      surveyState.answers.find(findAnswer(question)) ?? {
        questionId: question.id,
        type: "TrackingAnswer",
        value: "",
      }
    );
  }, [surveyState.answers, question]);

  const update = useCallback(() => {
    switch (question.trackingType) {
      case TrackingType.Query:
        answer.value = new URLSearchParams(window.location.search).get(question.propertyName) ?? "";
        break;

      case TrackingType.Cookie:
        answer.value =
          document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${question.propertyName}=`))
            ?.split("=")[1] ?? "";
        break;

      case TrackingType.LocalStorage:
        answer.value = localStorage.getItem(question.propertyName) ?? "";
        break;

      case TrackingType.SessionStorage:
        answer.value = sessionStorage.getItem(question.propertyName) ?? "";
        break;
    }

    setSurveyState((prevState) => {
      if (answer.value && !prevState.answers.includes(answer)) {
        return {
          ...prevState,
          answers: [...prevState.answers, answer],
        };
      } else if (!answer.value && prevState.answers.includes(answer)) {
        return {
          ...prevState,
          answers: prevState.answers.filter((a) => a !== answer),
        };
      }
      return prevState;
    });
  }, [question, answer, setSurveyState]);

  useEffect(() => update(), [update]);

  return !answer.value.trim() && question.messageForNoValue.trim() ? (
    <MissingValueContainer>{question.messageForNoValue}</MissingValueContainer>
  ) : null;
};

const MissingValueContainer = styled.div`
  margin: 1rem 0;
  color: var(--color-error-50);
`;
