import { createGlobalStyle } from "styled-components";
import { breakpoints } from "./utils/styleUtils";
import external from "./images/link-external.svg";
import close from "./images/close.svg";
import { localOrigins } from "./utils/urlUtils";

// Formatting of createGlobalStyle doesn't work with Prettier
// change it to css and, save, and back to createGlobalStyle
// as a fix.
export default createGlobalStyle`
  /*------------------------------------*\
  #BASE-PAGE
\*------------------------------------*/

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  html {
    color: var(--color-neutral-70);
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-default);
    font-size: var(--font-size-base);
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; */
    -webkit-text-size-adjust: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
  }

  body {
    height: 100%;
    overflow: hidden;
    margin: 0;
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
  }

  /*------------------------------------*\
  #BASE-TYPE
\*------------------------------------*/

  /*
 * Headings
 */
  h1,
  h2,
  legend,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-40);
    line-height: var(--line-height-default);
    font-weight: var(--font-weight-30);
    margin: var(--space-stack-default);

    &.tiny {
      font-family: var(--font-family-body);
      margin: var(--space-stack-20);
      color: var(--color-neutral-70);
      font-size: var(--font-size-30);
      font-weight: var(--font-weight-30);
    }

    &.small {
      font-size: var(--font-size-50);
    }

    &.large {
      font-size: var(--font-size-70);
      font-weight: var(--font-weight-30);
    }
  }

  h1 {
    font-size: var(--font-size-60);
  }

  h2 {
    font-size: var(--font-size-50);
  }

  h3 {
    font-size: var(--font-size-40);
  }

  /*
 * Paragraphs
 */
  p {
    margin: var(--space-stack-default);
    line-height: var(--line-height-30);

    &.large {
      font-size: var(--font-size-50);
      line-height: var(--line-height-30);
      font-weight: 300;
    }

    &.small {
      font-size: var(--font-size-20);
      line-height: var(--line-height-20);
      color: var(--color-neutral-50);
    }

    &.link {
      display: inline-flex;
      align-items: center;

      > a {
        font-size: var(--font-size-20);
        text-decoration: none;

        > .icon {
          display: inline-flex;
          transform: translateY(1px);
          height: 12px;
          width: 12px;

          &:last-child {
            margin-left: 3px;
          }

          &:first-child {
            margin-right: 3px;
          }

          > svg {
            stroke-width: 2px;
            fill: none;
            width: 12px;
            height: 12px;
            stroke: var(--color-primary-50);
          }
        }
      }
    }
  }

  /*
 * Links
 */
  a {
    color: var(--color-primary-50);
    cursor: pointer;

    &.not(.ext-icon-added) {
      &${localOrigins.map((o) => `:not([href^="${o}"])`)}:not([download]) {
        &:after {
          display: inline-block;
          content: "";
          width: 1rem;
          height: 1rem;
          background-image: url(${external});
          background-position: 1px 2px;
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }


    &:hover {
      color: var(--color-primary-70);
    }
    
    &[download] {
      display: block;
      width: max-content;
      padding: var(--space-20) var(--space-30);
      margin: var(--space-20) 0 var(--space-30);
      background-color: var(--color-primary-50);
      color: var(--color-neutral-10);
      text-decoration: none;
      border-radius: var(--space-10);

      &:hover {
        background-color: var(--color-primary-70);
      }
    }
  }
 
  .wp-block-button .wp-block-button__link {
    display: block;
    width: max-content;
    padding: var(--space-20) var(--space-30);
    margin: var(--space-20) 0 var(--space-30);
    background-color: var(--color-primary-50);
    color: var(--color-neutral-10);
    text-decoration: none;
    border-radius: var(--space-10);

    &:hover {
      background-color: var(--color-primary-70);
    }

    &:after {
      display: none !important;
    }
  }

  /*
  * Button
  */
  .sv_prev_btn,
  .sv_next_btn,
  .sv_complete_btn,
  .sv_pano_btn,
  .sv_q_file_remove_button,
  .sv_map_btn {
    border: 0;
    -webkit-appearance: none;
    border-radius: var(--border-radius-50);
    cursor: pointer;
    display: inline-block;
    font-family: var(--font-family-body);
    font-size: var(--font-size-default);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: var(--font-weight-30);
    line-height: var(--line-height-default) !important;
    margin: 0;
    padding: var(--space-squish-25);
    text-decoration: none;
    background: var(--color-neutral-10);
    color: var(--color-neutral-70);

    &:visited {
      color: var(--color-neutral-70);
    }

    &:hover {
      background: var(--color-neutral-30);
      color: var(--color-neutral-70);
    }

    &:active,
    &:focus {
      box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
    }

    &.small {
      font-size: var(--font-size-20);
      border-radius: var(--border-radius-default);
      padding: var(--space-squish-20);
    }

    &.large {
      padding: var(--space-squish-30);
    }

    &.sv_next_btn,
    &.sv_complete_btn {
      margin-right: 0px !important;
    }

    &.sv_next_btn,
    &.sv_complete_btn,
    &.primary {
      background: var(--color-primary-50);
      color: var(--color-white);

      &:hover {
        background: var(--color-primary-70);
        color: var(--color-white);
      }

      &:visited {
        color: var(--color-white);
      }

      &:active,
      &:focus {
        box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
      }
    }

    &.transparent {
      background-color: transparent;
      color: var(--color-primary-50);

      &:hover {
        background-color: transparent;
        color: var(--color-primary-50);
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0;
      }
    }

    &.error {
      background: var(--color-error-50);
      color: var(--color-white);

      &:visited {
        color: var(--color-white);
      }

      &:hover {
        background: var(--color-error-70);
        color: var(--color-white);
      }

      &:active,
      &:focus {
        box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
      }
    }

    &.disabled {
      background: var(--color-neutral-30);
      color: var(--color-neutral-50);
      pointer-events: none;

      &:visited {
        color: var(--color-neutral-50);
      }
    }
  }

  .sv-file__decorator {
    display: inline-block;
    label {
      display: inline-block;
      width: max-content;
      background-color: var(--color-primary-50);
      color: white;
      cursor: pointer;
      border-radius: var(--border-radius-50);
      font-family: var(--font-family-body);
      font-size: var(--font-size-default);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: var(--font-weight-30);
      line-height:  var(--line-height-default);
      margin: 0 1rem 0 0;
      padding: var(--space-squish-25);
    }
  }

  .sv_q_file_preview {
    border-radius: var(--border-radius-50);
    width: 100%;

    > img {
      max-width: 100%;
    }

    a[download] {
      pointer-events: none;
      margin: 0 0 0.5rem;
      background: none;
      color: black;
      padding: var(--space-20) 0;
    }
  }

  /*
  * Inputs
  */

  textarea {
    display: block;
    overflow: auto;
    resize: vertical;
    line-height: var(--line-height-30);
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral-10);
    border-radius: var(--border-radius-default);
    color: inherit;
    font-family: var(--font-family-body);
    font-size: var(--font-size-30);
    margin: 0;
    padding: 12px;
    width: 100%;
    transition: box-shadow 150ms var(--bezier-a);

    &:focus {
      border: 1px solid var(--color-primary-50);
      background-color: var(--color-white);
      box-shadow: 0px 0px 0px 3px var(--color-primary-20);
      outline: 0;
      color: var(--color-neutral-90);
      position: relative;
    }
  }

  input[type=text],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=email],
  input[type=tel],
  input[type=url] {
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral-10);
    border-radius: var(--border-radius-default);
    color: inherit;
    display: block;
    font-family: var(--font-family-body);
    font-size: var(--font-size-30);
    margin: 0;
    padding: 12px;
    width: 100%;
    transition: box-shadow 150ms var(--bezier-a);

    &:focus {
      background-color: var(--color-white);
      border: 1px solid var(--color-primary-50);
      box-shadow: 0px 0px 0px 3px var(--color-primary-20);
      outline: 0;
      color: var(--color-neutral-90);
      position: relative;
    }

    &:disabled {
      background: var(--color-neutral-10);
      color: var(--color-neutral-50);
    }
  }


  /*
  * UTILITIES
  */

  .u-d-n { display: none !important; }

  .u-d-f { display: flex; margin-bottom: 0 }

  .u-v-h { visibility: hidden !important; }

  .u-ta-l { text-align: left; }

  .u-ta-r { text-align: right; }

  .u-ta-c { text-align: center; }

  .u-f-l { float: left; }

  .u-f-r { float: right; }

  .u-td-lt { text-decoration: line-through; }

  .u-m-0 {
    margin: 0;
  }

  .u-ph-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .u-mb-40_b20 {
    @media ${breakpoints[20]} {
      margin-bottom: var(--space-40);
    }
  }

  /*
 * Other
 */
  small {
    color: var(--color-neutral-50);
    font-size: var(--font-size-20);

    &.description {
      line-height: var(--line-height-20);
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: var(--font-weight-30);
  }

  code,
  pre {
    font-family: "courier new", monospace;
  }

  pre {
    margin: 0;
    overflow-x: auto;
  }

  abbr {
    border-bottom: 1px dotted var(--color-neutral-50);
  }

  del {
    text-decoration: line-through;
  }

  mark {
    background: color-mod(yellow alpha(-50%));
  }

  figure {
    margin: 0;

    iframe {
      max-width: 100%;
      height: unset;
      aspect-ratio: 16 / 9;
    }
  }

  hr {
    background-color: transparent;
    border: solid var(--color-neutral-30);
    border-width: 1px 0 0;
    clear: both;
    margin: 2.5rem var(--space-default);
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  *:focus {
    outline-color: var(--color-primary-50);
  }

  #krpano {
    z-index: 24;
  }

  .mapboxgl-ctrl-group {
    && button {
      border-radius: 0px;
    }
  }

  .react-viewer.react-viewer-transition {
    transition-duration: 0s;

    .react-viewer-mask {
      background: rgba(55,55,55,0.85);
    }

    .react-viewer-close {
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--color-neutral-20);
      &:hover {
        background-color: var(--color-neutral-10);
      }

      i {
        :before {
          content: unset;
        }
        content: url(${close});
        filter: invert(1);
        width: 18px;
        height: 18px;
        top: 7px;
        left: 7px;
      }
    }
  }
`;
