import styled from "styled-components";
import { breakpoints } from "../../utils/styleUtils";
import Icon from "../../shared/components/Icon";
import { NavLink } from "react-router-dom";

export const StyledNavigation = styled.nav`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  min-height: 86px;
  flex-flow: row nowrap;
  background-color: var(--color-neutral-10);
  box-shadow: var(--box-shadow-inset);
  padding: 4px 16px;

  @media ${breakpoints[30]} {
    flex-flow: row wrap;
    justify-content: space-around;
  }

  &.embed {
    min-height: 40px;
    max-height: 40px;
    line-height: 40px;
    justify-content: flex-start;

    p {
      margin: 0;
    }
  }
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 8px 4px;
  text-decoration: none;
  color: var(--color-neutral-70);

  @media ${breakpoints[30]} {
    padding: 16px 4px;
  }

  &:hover {
    color: var(--color-primary-50);
  }

  &.active {
    color: var(--color-primary-50);
  }
`;

export const NavigationItemText = styled.span`
  font-size: var(--font-size-30);
  font-weight: 600;
`;

export const NavigationItemIcon = styled(Icon)`
  margin-bottom: 8px;
`;
