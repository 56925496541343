import { WordpressParticipationItem, ParticipationItem, LayerGroup, Pano } from "../types";
import { upsert } from "./areaUtils";

function transformWordpressParticipationItem(
  data: WordpressParticipationItem,
  layerGroups: LayerGroup[],
  panoramas: Pano[],
): ParticipationItem {
  const image = data.acf.image;
  const latitude = Number(data.acf.map_location?.latitude) || undefined;
  const longitude = Number(data.acf.map_location?.longitude) || undefined;

  if (data.acf.map_location && latitude !== undefined && longitude !== undefined) {
    const scenarios = data.acf.map_location.scenarios || [];
    const layers = data.acf.map_location.layers || [];

    // Add pois to scenario's in the map
    layerGroups
      .filter((l) => layers.includes(l.id))
      .forEach((layerGroup) => {
        layerGroup.scenarios
          .filter((s) => scenarios.includes(s.id))
          .forEach((scenario) => {
            upsert(["slug", "type"], scenario.pois, {
              slug: data.slug || `draft-${data.id}`,
              latitude: latitude,
              longitude: longitude,
              title: data.title.rendered,
              type: "question",
            });
          });
      });
  }

  const resultsLayergroupSlug = layerGroups.find((l) => l.id === data.acf.results?.layergroup)
    ?.slug;
  const resultsPanoSlug = panoramas.find((p) => p.id === data.acf.results?.panorama)?.slug;

  return {
    id: data.id,
    title: data.title.rendered,
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    showInMenu: data.acf.show_in_menu,
    image,
    slug: data.slug || `draft-${data.id}`,
    surveyId: data.acf.resource_survey,
    apiKey: data.acf.survey_public_api_key,
    latitude,
    longitude,
    status: data.status,
    resultsCookieName: data.acf.results?.cookie,
    resultsLayergroupSlug,
    resultsPanoSlug,
  };
}

export function transformWordpressParticipation(
  data: WordpressParticipationItem[],
  layerGroups: LayerGroup[],
  panoramas: Pano[],
) {
  return data.map((item) => transformWordpressParticipationItem(item, layerGroups, panoramas));
}

const getCookie = (name: string) =>
  decodeURIComponent(document.cookie)
    .split("; ")
    .find((c) => c.startsWith(`${name}=`))
    ?.split("=")[1];

export function isSurveyCompleted(survey: ParticipationItem) {
  try {
    if (survey.resultsCookieName) {
      return !!getCookie(survey.resultsCookieName);
    }
  } catch (e) {}
  return false;
}
