import * as Survey from "survey-react";
import { locationPicker } from "../components/Participation/LocationPicker";
import { ParticipationState } from "../InterfaceContext";
import {
  BooleanQuestionModel,
  ChoiceQuestionModel,
  CreateBooleanAnswerModel,
  CreateChoiceAnswerModel,
  CreateLocationPickerAnswerModel,
  CreateRatingAnswerModel,
  CreateTrackingAnswerModel,
  LocationPickerQuestionModel,
  RatingQuestionModel,
  TrackingQuestionModel,
} from "../tim-survey/EndUserApi";
import { UseStateReturn } from "../types";
import { getLocalStorage, setLocalStorage } from "./corsUtils";
import { resolveLocale } from "./intlUtils";

const localStorageCommonValueKeys = ["email", "zipcode"];

export const checkForCommonFields = async () => {
  const rv: { [x: string]: string | null } = {};

  await Promise.all(
    localStorageCommonValueKeys.map(async (val) => {
      rv[val] = await getLocalStorage(val);
    })
  );

  return rv;
};

export const fillCommonFields = (
  commonValues: { [x: string]: string | null },
  survey: Survey.Model
) => {
  Object.keys(commonValues)
    .map((k) => k as keyof typeof commonValues)
    .filter((k) => commonValues[k] !== undefined)
    .forEach((k) => {
      const question = survey.getAllQuestions().find((q) => q.name === k);
      if (!question) return;
      const passesValidation = question.getValidators().every((v) => {
        return v.validate(commonValues[k]) === null;
      });
      question.value = passesValidation ? commonValues[k] : undefined;
    });
};

export const setCommonValueListener = (survey: Survey.Model) => {
  const questionNames = localStorageCommonValueKeys;
  survey.getAllQuestions().forEach((question) => {
    if (questionNames.includes(question.name)) {
      question.valueChangedCallback = () => {
        setLocalStorage(question.name, question.value);
      };
    }
  });
};

export const setSelectionListener = (
  survey: Survey.Model,
  callback: UseStateReturn<ParticipationState>[1]
) => {
  const questions = survey.getAllQuestions();
  questions.forEach((question) => {
    question.valueChangedCallback = () => {
      const layerSlug =
        question.maplayermeta?.[question.value] ?? (question as any).page.maplayermeta;
      const scenarioSlug =
        question.scenariometa?.[question.value] ?? (question as any).page.scenariometa;
      const panoSlug = question.panometa?.[question.value] ?? (question as any).page.panometa;
      const panoLookat = question.panolookat?.[question.value] ?? (question as any).page.panolookat;

      callback((state) => ({
        layerSlug: layerSlug ?? state.layerSlug,
        scenarioSlug: scenarioSlug ?? state.scenarioSlug,
        panoSlug: panoSlug ?? state.panoSlug,
        navigatedPano: question.panometa?.[question.value] ? false : state.navigatedPano,
        panoLookat,
      }));
    };
  });
};

export const setNavigationListener = (
  survey: Survey.Model,
  callback: UseStateReturn<ParticipationState>[1]
) => {
  survey.onAfterRenderPage.add(() => {
    const answeredQuestionWithMaplayerMeta = survey.currentPage.questions.find(
      (q: any) => q.value && q.maplayermeta
    );
    const layerSlug =
      answeredQuestionWithMaplayerMeta?.maplayermeta?.[answeredQuestionWithMaplayerMeta.value] ??
      survey.currentPage.maplayermeta ??
      "";

    const answeredQuestionWithScenarioMeta = survey.currentPage.questions.find(
      (q: any) => q.value && q.scenariometa
    );
    const scenarioSlug =
      answeredQuestionWithScenarioMeta?.scenariometa?.[answeredQuestionWithScenarioMeta.value] ??
      survey.currentPage.scenariometa ??
      "";

    const answeredQuestionWithPanoMeta = survey.currentPage.questions.find(
      (q: any) => q.value && q.panometa
    );
    const panoSlug =
      answeredQuestionWithPanoMeta?.panometa?.[answeredQuestionWithPanoMeta.value] ??
      survey.currentPage.panometa ??
      "";

    const answeredQuestionWithPanoLookat = survey.currentPage.questions.find(
      (q: any) => q.value && q.panolookat
    );
    const panoLookat =
      answeredQuestionWithPanoLookat?.panolookat?.[answeredQuestionWithPanoLookat.value] ??
      survey.currentPage.panolookat;

    callback({ layerSlug, scenarioSlug, panoSlug, panoLookat, navigatedPano: false });
  });
};

export const setUpCustomProperties = () => {
  let locale = resolveLocale();

  if (locale === "sco") locale = "en";

  // user question
  Survey.surveyLocalization.defaultLocale = locale;
  Survey.Serializer.addProperty("survey", {
    name: "locale",
    default: locale,
  });
  Survey.CustomWidgetCollection.Instance.addCustomWidget(locationPicker, "customtype");
  Survey.Serializer.addClass("locationpicker", [], null as any, "comment");

  // participation
  Survey.Serializer.addProperty("page", {
    name: "mapLayer",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("selectbase", {
    name: "maplayermeta",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("selectbase", {
    name: "scenariometa",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("selectbase", {
    name: "panometa",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("selectbase", {
    name: "panolookat",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("page", {
    name: "maplayermeta",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("page", {
    name: "scenariometa",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("page", {
    name: "panometa",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("page", {
    name: "panolookat",
    default: null,
    category: "general",
  });
  Survey.Serializer.addProperty("page", {
    name: "boundingbox",
    default: null,
    category: "general",
  });
};

export const minimumPinsPlaced = (
  question: LocationPickerQuestionModel,
  answer?: CreateLocationPickerAnswerModel
) => {
  const required = question.required || question.pins.some((p) => p.minAmount > 0);
  if (!required) return true;
  if (!answer) return false;
  if (question.pins.every((p) => !p.minAmount)) {
    return answer.pins.length > 0;
  }
  return question.pins.every((p) => {
    if (!p.minAmount) return true;
    if (!answer) return false;
    return answer.pins.filter((pin) => pin.pinDefinition === p.id).length >= p.minAmount;
  });
};

export const optionSelected = (question: ChoiceQuestionModel, answer?: CreateChoiceAnswerModel) =>
  !question.required || (answer?.choices.some((c) => c.selected) ?? false);

export const booleanAnswered = (
  question: BooleanQuestionModel,
  answer?: CreateBooleanAnswerModel
) => !question.required || typeof answer?.bool === "boolean";

export const ratingAnswered = (question: RatingQuestionModel, answer?: CreateRatingAnswerModel) =>
  !question.required || typeof answer?.rating === "number";

export const trackingAnswered = (
  question: TrackingQuestionModel,
  answer?: CreateTrackingAnswerModel
) => !question.required || !!answer?.value.trim();
