import styled from "styled-components";
import { breakpoints } from "../../utils/styleUtils";
import { Popup } from "react-map-gl";
import Close from "./../../images/close.svg";

export const MapContainer = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  overflow: hidden auto;
  position: relative;
  transition: opacity var(--bezier-a) 0.6s;

  @media ${breakpoints[10]} {
    grid-column: 1 / 4;
  }

  .mapboxgl-ctrl-bottom-left {
    display: none !important;
  }

  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
    margin: 0 15px 15px 0;
  }

  .mapbox-improve-map {
    display: none !important;
  }

  .mapboxgl-ctrl-attrib-inner a:after {
    display: none !important;
  }

  &.is-on-feature .overlays {
    cursor: pointer !important;
  }

  .mapboxgl-map {
    font-family: var(--font-family-body);
  }

  .mapboxgl-marker {
    /* Ensure markers do not break the stacking context */
    will-change: auto;
  }

  /* Map internals */
  display: grid;
`;

export const MarkerIconStyles = styled.div`
  width: auto;
  height: auto;
  display: inline-block;
  cursor: pointer;

  > svg {
    pointer-events: none;
    width: 44px;
    height: 44px;

    > path {
      pointer-events: auto;
      fill: var(--color-primary-50);
    }
  }

  &.high {
    > svg {
      width: 46px;
      height: 46px;
    }
  }

  &.question {
    > svg {
      width: auto;
      height: auto;
    }
  }

  &.phantom {
    opacity: 0.75;
    cursor: default;
  }
`;

export const LabelStyles = styled.div`
  background-color: #556DFF;
  box-shadow: var(--box-shadow-25);
  line-height: var(--line-height-20);
  padding: var(--space-10) var(--space-30);
  color: var(--color-white);
  text-align: center;
  font-size: var(--font-size-10);
  font-weight: 400;
  border-radius: 32px;
  outline: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const PoiContentStyles = styled(Popup)`
  display: none;

  @media ${breakpoints[10]} {
    display: flex;
  }

  img {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  }

  .mapboxgl-popup-tip {
    border-width: 5px;
  }

  .mapboxgl-popup-content {
    padding: 0;
    max-width: 350px;
    border-radius: var(--border-radius-default);
    font-family: var(--font-family-body);
    font-size: var(--font-size-default);
    box-shadow: var(--box-shadow-20);

    > div {
      > div {
        padding: var(--space-inset-default);

        > :first-child {
          max-width: calc(100% - 2rem);
        }

        > h3:only-child {
          margin: var(--space-stack-default);
        }
      }
    }
  }

  &.with-image {
    .mapboxgl-popup-content {
      width: 450px;
      max-width: 450px;
    }
  }

  .mapboxgl-popup-close-button {
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(25, 24, 37, 0.8);
    transition: background-color 200ms var(--bezier-a);
    color: transparent;

    &:hover {
      background-color: rgba(25, 24, 37, 0.95);
    }

    &:after {
      top: 3px;
      left: 3px;
      height: 16px;
      width: 16px;
      position: absolute;
      display: block;
      content: "";
      background: url(${Close});
    }
  }
`;

export const LegendPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: var(--space-inset-default);
  }

  svg {
    stroke-width: 2px;
  }

  svg + span {
    margin-left: var(--space-25);
  }
`;

export const DefaultLegendItem = styled.span`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: solid transparent 4px;
  box-sizing: border-box;
  margin-right: 12px;
`;
