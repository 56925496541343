import React from "react";
import { MapPoi } from "../../types";

import { RotationMarker } from "../../components/Map/RotationMarker";
import { DefaultMarker } from "../../components/Map/DefaultMarker";
import { ReactComponent as UserPin } from "../../images/markers/pinUser.svg";
import { getTokenValue } from "../../TokenStyles";

export enum MarkerTypes {
  Default = "default",
  Question = "question",
  Faq = "faq",
  Info = "info",
  Pano = "pano",
  Video = "video",
  Edit = "edit",
  Vcr = "vcr",
}

export const markerSVGSwitch = (poi: MapPoi, selected: boolean, color?: string) => {
  const baseColor = color?.startsWith("--color-") ? getTokenValue(color) : undefined;
  const selectedColor = color?.startsWith("--color-") && color.endsWith("50") ? getTokenValue(color.replace("50", "70")) : undefined;

  switch (poi.type) {
    case MarkerTypes.Pano:
    case MarkerTypes.Video:
    case MarkerTypes.Vcr:
      return (
        <RotationMarker
          type={poi.type}
          direction={poi.direction}
          height={poi.height}
          selected={selected}
          color={baseColor}
          selectedColor={selectedColor}
        />
      );
    case MarkerTypes.Faq:
    case MarkerTypes.Question: // fallback for existing poi types, might implement again later
      return <UserPin />;
    case MarkerTypes.Info:
    case MarkerTypes.Default:
    default:
      return (
        <DefaultMarker
          selected={selected}
          color={baseColor}
          selectedColor={selectedColor}
        />
      );
  }
};
