import { Fragment, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { DefaultMarker } from "../components/Map/DefaultMarker";
import Button from "../shared/components/Button";
import { useSurveyState } from "../SurveyContext";
import { CreateLocationPickerAnswerModel, LocationPickerQuestionModel } from "./EndUserApi";

type Props = {
  question: LocationPickerQuestionModel;
};

export const PlacePinQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const activatePin = useCallback(
    (pinId: number) => {
      setSurveyState((prevState) => ({
        ...prevState,
        activePinId: pinId === prevState.activePinId ? undefined : pinId,
      }));
    },
    [setSurveyState]
  );

  const pinIsDisabled = useCallback(
    (pinId: number) => {
      const pin = question.pins.find((p) => p.id === pinId);
      if (!pin || !pin.maxAmount) return false;
      const answer = surveyState.answers.find(
        (a) => a.questionId === question.id
      ) as CreateLocationPickerAnswerModel;
      if (!answer) return false;
      return answer.pins.filter((p) => p.pinDefinition === pinId).length >= pin.maxAmount;
    },
    [question.id, question.pins, surveyState.answers]
  );

  const pinCount = useCallback(
    (pinId: number) => {
      const answer = surveyState.answers.find(
        (a) => a.questionId === question.id
      ) as CreateLocationPickerAnswerModel;
      if (!answer) return 0;
      return answer.pins.filter((p) => p.pinDefinition === pinId).length;
    },
    [question.id, surveyState.answers]
  );

  return (
    <>
      {question.title && <h3>{question.title}</h3>}
      {question.content && <p>{question.content}</p>}
      <div style={{ marginBottom: "1rem" }}>
        {question.pins.map((p) => {
          return (
            <Fragment key={p.id}>
              <ButtonContainer key={p.id}>
                <Button
                  onClick={() => activatePin(p.id)}
                  disabled={pinIsDisabled(p.id)}
                  className={surveyState.activePinId === p.id ? "active" : ""}
                >
                  <ButtonContent>
                    <DefaultMarker color={p.color} />
                    <div>{p.label}</div>
                  </ButtonContent>
                </Button>
                {p.maxAmount && (
                  <div>
                    {pinCount(p.id)} / {p.maxAmount}
                  </div>
                )}
              </ButtonContainer>
              {p.minAmount !== 0 && (
                <MinAmountText>
                  <FormattedMessage
                    id="place-pin-question.min-amount"
                    defaultMessage="{count, plural, one {place at least # pin} other {place at least # pins}}"
                    values={{ count: p.minAmount }}
                  />
                </MinAmountText>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

const ButtonContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;

  > div {
    margin: 1.25rem 0 0 1rem;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;

  > svg {
    height: 2rem;
    overflow: visible;
  }
`;

const MinAmountText = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
`;
