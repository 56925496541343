import React from "react";
import {
  StyledMenuLink,
  MenuItemImage,
  MenuItemTitle,
  MenuItemIntroduction,
} from "./MenuItemStyles";
import classNames from "classnames";

type Props = {
  to: string;
  title: string;
  subtitle?: string;
  imgUrl?: string;
  imgLarge?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const MenuListItem = (props: Props) => {
  return (
    <StyledMenuLink
      to={props.to}
      className={classNames(props.className, { flex: props.imgUrl && !props.imgLarge })}
      onClick={props.onClick}
    >
      {props.imgUrl && (
        <MenuItemImage>
          <img src={props.imgUrl} alt={props.title} />
        </MenuItemImage>
      )}
      <div>
        <MenuItemTitle>{props.title}</MenuItemTitle>
        {props.subtitle && <MenuItemIntroduction>{props.subtitle}</MenuItemIntroduction>}
      </div>
    </StyledMenuLink>
  );
};
