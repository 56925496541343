/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type BooleanQuestionModel = QuestionModel & {
  type: string;
  label: string;
  defaultValue: boolean;
};

export interface ChoiceModel {
  value: string;
  selected: boolean;
}

export type ChoiceQuestionModel = QuestionModel & {
  type: string;
  options: StringOptionModel[];
  allowMultiple: boolean;
  isDropdown: boolean;
  defaultValue?: string | null;
};

export interface CreateAnswerModel {
  /**
   * @format int32
   * @min 1
   */
  questionId: number;
  type: string;
}

export type CreateBooleanAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  bool: boolean;
  type: string;
};

export type CreateChoiceAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  type: string;
  choices: ChoiceModel[];
};

export type CreateFileUploadAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  /**
   * @format int32
   * @max 10
   */
  amountOfFiles: number;
  type: string;
};

export type CreateLocationPickerAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  /** @minItems 1 */
  pins: (PinModel | GeoLocationPinModel | PanoLocationPinModel)[];
  type: string;
};

export type CreateRatingAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  type: string;
  /** @format double */
  rating: number;
};

export interface CreateResponseModel {
  /** @minItems 1 */
  answers: (
    | CreateAnswerModel
    | CreateBooleanAnswerModel
    | CreateChoiceAnswerModel
    | CreateFileUploadAnswerModel
    | CreateLocationPickerAnswerModel
    | CreateRatingAnswerModel
    | CreateTextAnswerModel
    | CreateTrackingAnswerModel
  )[];
}

export type CreateTextAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  /**
   * @minLength 1
   * @maxLength 5242880
   */
  text: string;
  type: string;
};

export type CreateTrackingAnswerModel = UtilRequiredKeys<CreateAnswerModel, "type"> & {
  /** @minLength 1 */
  value: string;
  type: string;
};

export interface DoubleOptionModel {
  /** @format double */
  value: number;
  label?: string | null;
}

export interface ElementListItemModel {
  type: string;
  /** @format int32 */
  id: number;
}

export type ElementModel = UtilRequiredKeys<ElementListItemModel, "type"> & {
  title: string;
  content: string;
  type: string;
};

export interface FileUploadEndpointReferenceModel {
  /** @format int32 */
  answerId: number;
  /** @format int32 */
  questionId: number;
}

export type FileUploadQuestionModel = QuestionModel & {
  /** @format int32 */
  maxAmount: number;
  /** @format int64 */
  maxFileSize: number;
  allowedTypes: string[];
  type: string;
};

export type GeoLocationPinModel = UtilRequiredKeys<PinModel, "type"> & {
  /** @format int32 */
  layerId: number;
  type: string;
};

export type LocationPickerQuestionModel = QuestionModel & {
  pins: PinDefinitionModel[];
  type: string;
  /** @format int32 */
  layer?: number | null;
  /** @format int32 */
  panorama?: number | null;
  restrictions: RestrictionLayerModel[];
};

export interface PageListItemModel {
  /** @format int32 */
  id: number;
  title: string;
  description: string;
  /** @format int32 */
  elements: number;
}

export interface PageModel {
  /** @format int32 */
  id: number;
  title: string;
  description: string;
  elements: (
    | ElementModel
    | QuestionModel
    | BooleanQuestionModel
    | ChoiceQuestionModel
    | FileUploadQuestionModel
    | LocationPickerQuestionModel
    | RatingQuestionModel
    | TextQuestionModel
    | TrackingQuestionModel
  )[];
  /** @format int32 */
  layer?: number | null;
  /** @format int32 */
  panorama?: number | null;
  /** @format int32 */
  scenario?: number | null;
}

export type PanoLocationPinModel = UtilRequiredKeys<PinModel, "type"> & {
  /** @format int32 */
  panoId: number;
  type: string;
};

export interface PinDefinitionModel {
  /** @format int32 */
  id: number;
  icon: string;
  label: string;
  color?: string | null;
  /** @format int32 */
  minAmount: number;
  /** @format int32 */
  maxAmount?: number | null;
  allowComment: boolean;
}

export interface PinModel {
  /** @format int32 */
  pinDefinition: number;
  /**
   * @format double
   * @min -90
   * @max 90
   */
  latitude: number;
  /**
   * @format double
   * @min -180
   * @max 180
   */
  longitude: number;
  /** @format int32 */
  scenarioId: number;
  /** @maxLength 1000 */
  comment: string;
  type: string;
}

export type QuestionModel = ElementModel & {
  required: boolean;
};

export interface QuestionnaireListItemModel {
  /** @format int32 */
  id: number;
  name: string;
  state: SurveyState;
  questionnaireType: QuestionnaireType;
}

export interface QuestionnaireModel {
  /** @format int32 */
  id: number;
  name: string;
  singleEntryPolicy: boolean;
  state: SurveyState;
  pages: PageModel[];
  questionnaireType: QuestionnaireType;
}

/** @format int32 */
export type QuestionnaireType = 0 | 1;

export type RatingQuestionModel = QuestionModel & {
  type: string;
  options: DoubleOptionModel[];
  /** @format double */
  defaultValue?: number | null;
};

export interface RestrictionLayerModel {
  pins: number[];
  cluster: string[];
  restrictionType: RestrictionType;
  message: string;
}

/** @format int32 */
export type RestrictionType = 0 | 1;

export interface StringOptionModel {
  value: string;
  label?: string | null;
}

/** @format int32 */
export type SurveyState = 0 | 1;

export type TextQuestionModel = QuestionModel & {
  subType: string;
  /** @format int32 */
  minLength?: number | null;
  /** @format int32 */
  maxLength?: number | null;
  type: string;
};

export type TrackingQuestionModel = QuestionModel & {
  trackingType: TrackingType;
  propertyName: string;
  messageForNoValue: string;
  type: string;
};

/** @format int32 */
export type TrackingType = 0 | 1 | 2 | 3 | 4;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        },
        signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
        body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title TIM Survey EndUser API
 * @version v1
 */
export class EndUserApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  surveys = {
    /**
     * No description
     *
     * @tags Elements
     * @name ListQuestionsFromSurvey
     * @request GET:/surveys/{surveyId}/elements/questions
     * @secure
     */
    listQuestionsFromSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<
        (
          | ElementModel
          | QuestionModel
          | BooleanQuestionModel
          | ChoiceQuestionModel
          | FileUploadQuestionModel
          | LocationPickerQuestionModel
          | RatingQuestionModel
          | TextQuestionModel
          | TrackingQuestionModel
        )[],
        any
      >({
        path: `/surveys/${surveyId}/elements/questions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name ListElementsFromSurveyPage
     * @request GET:/surveys/{surveyId}/pages/{pageId}/elements
     * @secure
     */
    listElementsFromSurveyPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<
        (
          | ElementModel
          | QuestionModel
          | BooleanQuestionModel
          | ChoiceQuestionModel
          | FileUploadQuestionModel
          | LocationPickerQuestionModel
          | RatingQuestionModel
          | TextQuestionModel
          | TrackingQuestionModel
        )[],
        any
      >({
        path: `/surveys/${surveyId}/pages/${pageId}/elements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name GetElement
     * @request GET:/surveys/{surveyId}/elements/{elementId}
     * @secure
     */
    getElement: (surveyId: number, elementId: number, params: RequestParams = {}) =>
      this.request<
        | ElementModel
        | QuestionModel
        | BooleanQuestionModel
        | ChoiceQuestionModel
        | FileUploadQuestionModel
        | LocationPickerQuestionModel
        | RatingQuestionModel
        | TextQuestionModel
        | TrackingQuestionModel,
        any
      >({
        path: `/surveys/${surveyId}/elements/${elementId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name ListPages
     * @request GET:/surveys/{surveyId}/pages
     * @secure
     */
    listPages: (surveyId: number, params: RequestParams = {}) =>
      this.request<PageListItemModel[], any>({
        path: `/surveys/${surveyId}/pages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPage
     * @request GET:/surveys/{surveyId}/pages/{pageId}
     * @secure
     */
    getPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<PageModel, any>({
        path: `/surveys/${surveyId}/pages/${pageId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name ListPinDefinitions
     * @request GET:/surveys/{surveyId}/elements/{questionId}/pindefinitions
     * @secure
     */
    listPinDefinitions: (surveyId: number, questionId: number, params: RequestParams = {}) =>
      this.request<PinDefinitionModel[], any>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name GetPinDefinition
     * @request GET:/surveys/{surveyId}/elements/{questionId}/pindefinitions/{pinDefinitionId}
     * @secure
     */
    getPinDefinition: (
      surveyId: number,
      questionId: number,
      pinDefinitionId: number,
      params: RequestParams = {}
    ) =>
      this.request<PinDefinitionModel, any>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions/${pinDefinitionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Responses
     * @name CreateResponse
     * @request POST:/surveys/{surveyId}/responses
     * @secure
     */
    createResponse: (surveyId: number, data: CreateResponseModel, params: RequestParams = {}) =>
      this.request<FileUploadEndpointReferenceModel[], any>({
        path: `/surveys/${surveyId}/responses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name ListSurveys
     * @request GET:/surveys
     * @secure
     */
    listSurveys: (params: RequestParams = {}) =>
      this.request<QuestionnaireListItemModel[], any>({
        path: `/surveys`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurvey
     * @summary Retrieves a specific Survey including its Pages and Elements.
     * @request GET:/surveys/{surveyId}
     * @secure
     */
    getSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<QuestionnaireModel, void>({
        path: `/surveys/${surveyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name UploadFile
     * @request POST:/files/{answerId}
     * @secure
     */
    uploadFile: (
      answerId: number,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<number, any>({
        path: `/files/${answerId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
}
