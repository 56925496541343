import { useMemo } from "react";
import styled from "styled-components";
import { useSurveyState } from "../SurveyContext";
import { BooleanQuestionModel, CreateBooleanAnswerModel } from "./EndUserApi";

type Props = {
  question: BooleanQuestionModel;
};

export const BooleanQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const answer = useMemo(() => {
    return surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateBooleanAnswerModel;
  }, [surveyState.answers, question.id]);

  const update = (e: React.ChangeEvent<HTMLInputElement>) => {
    let answer = surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateBooleanAnswerModel;
    if (!answer) {
      answer = {
        questionId: question.id,
        type: "BooleanAnswer",
        bool: e.target.checked,
      } as CreateBooleanAnswerModel;
    } else {
      answer.bool = e.target.checked;
    }
    setSurveyState((prevState) => ({
      ...prevState,
      answers: [...prevState.answers.filter((a) => a.questionId !== question.id), answer!],
    }));
  };

  return (
    <OpenQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}

      <CheckboxLabel htmlFor={`question-${question.id}`}>
        <input
          type="checkbox"
          onChange={update}
          id={`question-${question.id}`}
          checked={answer?.bool}
        />
        {question.label}
      </CheckboxLabel>
    </OpenQuestionContainer>
  );
};

const OpenQuestionContainer = styled.div`
  margin: 1rem 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  width: fit-content;
  padding-right: var(--space-30);
  align-items: center;
  background-color: var(--color-neutral-10);
  border-radius: var(--border-radius-10);
  color: black;

  > input {
    margin: var(--space-20);
  }
`;
