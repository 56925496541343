export function makeKeyDownHandler<T extends Element>(handler?: React.EventHandler<any>) {
  if (!handler) return () => {};

  return function (event: React.KeyboardEvent<T>) {
    if (event.key === "Enter" || event.key === " ") {
      handler(event);

      event.preventDefault();
    }
  };
}

export function makeNativeKeyDownHandler(
  handler?: EventListenerOrEventListenerObject
) {
  if (!handler) return () => {};

  return function (event: KeyboardEvent) {
    if (event.key === "Enter" || event.key === " ") {
      if (typeof handler === "function") {
        handler(event);
      } else {
        handler.handleEvent(event);
      }

      event.preventDefault();
    }
  };
}
