import { Content, StyledLayoutSidebar, StyledSidebar } from "./SidebarStyles";

import Header from "../../shared/components/Header";
import { MemoizedNavigation } from "./../Navigation/Navigation";
import NavigationItem from "./../Navigation/NavigationItem";
import SidebarToggle from "./SidebarToggle";
import { breakpoints } from "../../utils/styleUtils";
import classNames from "classnames";
import { useAppState } from "../../AppContext";
import { useMediaQuery } from "react-responsive";
import { ClickOverlay } from "../../shared/components/ClickOverlay";
import IsMobile from "../../shared/components/IsMobile";
import { useInterfaceState } from "../../InterfaceContext";
import { SidebarBody } from "./SidebarBody";
import Skeleton from "react-loading-skeleton";
import { ReservedMenuIndices } from "../../utils/menuUtils";
import { useEffect, useMemo } from "react";
import { useRouteState } from "../../hooks/useRouteState";
import { messages } from "../../global-intl-messages";
import { FormattedMessage } from "react-intl";
import { useDebugContext } from "../../DebugContext";

export default function Sidebar() {
  const { state } = useAppState();
  const { setInterfaceState, interfaceState } = useInterfaceState();
  const routeState = useRouteState();
  const { available: debugAvailable } = useDebugContext();

  const isBigScreen = useMediaQuery({ query: breakpoints[30] });

  const closeSidebarMobile = () => {
    if (isBigScreen || !interfaceState.sidebarOpen) {
      return;
    }
    setInterfaceState({
      ...interfaceState,
      sidebarOpen: false,
    });
  };

  useEffect(() => {
    if (isBigScreen && interfaceState.currentEntrance?.type === "map" && !routeState.panoSlug) {
      setInterfaceState({ ...interfaceState, sidebarOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const homeLink = useMemo(() => {
    if (state.landingPage) {
      return "/@";
    }
    if (interfaceState.currentEntrance) {
      return interfaceState.currentEntrance.type !== "map"
        ? "/pano/" + interfaceState.currentEntrance.pano.slug
        : "/map";
    }
    return "/";
  }, [interfaceState.currentEntrance, state.landingPage]);

  return (
    <>
      <IsMobile>
        {interfaceState.sidebarOpen && (
          <ClickOverlay
            onClick={(e) => {
              closeSidebarMobile();
              e.preventDefault();
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </IsMobile>
      <StyledLayoutSidebar
        className={classNames({
          "is-closed": !interfaceState.sidebarOpen,
          "is-open": interfaceState.sidebarOpen,
          "is-hidden": !!document.getElementById("question-el"),
        })}
      >
        <StyledSidebar>
          <SidebarToggle
            onClick={() =>
              setInterfaceState({
                ...interfaceState,
                sidebarOpen: !interfaceState.sidebarOpen,
              })
            }
          />
          <Header
            loading={state.initializing}
            logoPath={state.logo}
            alt={state.name}
            description={state.description}
            homeUrlPath={homeLink}
          >
            {state.name}
          </Header>
          <MemoizedNavigation>
            {state.menuItems && (
              <NavigationItem index={ReservedMenuIndices.Home} icon="home" to={homeLink}>
                <FormattedMessage {...messages.homeButton} />
              </NavigationItem>
            )}
            {state.menuItems.map((item, i) => (
              <NavigationItem key={item.name} index={i} icon={item.icon}>
                {item.name}
              </NavigationItem>
            ))}
            {debugAvailable && (
              <NavigationItem index={ReservedMenuIndices.Debug} icon="home">
                Debug
              </NavigationItem>
            )}
          </MemoizedNavigation>
          {state.initializing ? (
            <Content>
              <p>
                {/*@ts-ignore */}
                <Skeleton count={6} />
              </p>
            </Content>
          ) : (
            <SidebarBody />
          )}
        </StyledSidebar>
      </StyledLayoutSidebar>
    </>
  );
}
