import { MenuItem, WordpressMenuItem } from "../types";

type MultiLevelWordpressMenuItem = WordpressMenuItem & {
  children: MultiLevelWordpressMenuItem[];
};

/** remove unsupported post archives */
export function transformWordpressMenu(data: WordpressMenuItem[]): MenuItem[] {
  const multiLevelMenu = transformToMultiLevel(data);

  return multiLevelMenu.map(transformWordpressMenuItem).filter(filterSupportedMenuItemTypes);
}

const transformToMultiLevel = (
  data: WordpressMenuItem[],
  parent = 0
): MultiLevelWordpressMenuItem[] => {
  return data
    .filter((item) => Number(item.menu_item_parent) === parent)
    .map((currentLevelMenuItem) => ({
      ...currentLevelMenuItem,
      children: transformToMultiLevel(data, currentLevelMenuItem.ID),
    }));
};

const transformWordpressMenuItem = (menuItem: MultiLevelWordpressMenuItem): MenuItem => {
  const determinedType = determineMenuItem(menuItem);
  const icon = menuItem.acf.icon || determineIcon(determinedType);
  const draft = !!menuItem.acf.draft;
  const children = menuItem.children
    .map(transformWordpressMenuItem)
    .filter(filterSupportedMenuItemTypes);

  return {
    ...determinedType,
    name: menuItem.title,
    description: menuItem.description,
    icon,
    children,
    status: draft ? "draft" : "publish",
  };
};

const determineMenuItem = (menuItem: WordpressMenuItem) => {
  switch (menuItem.type) {
    case "post_type_archive":
      return {
        type: menuItem.object,
      };
    case "post_type":
      return {
        type:
          menuItem.object !== "page" && menuItem.menu_item_parent === "0"
            ? menuItem.object
            : "info",
        page: Number(menuItem.object_id),
      };
    case "custom":
      return {
        type: "link",
        url: menuItem.url,
        target: menuItem.target,
      };
    case "taxonomy":
      return {
        type: "taxonomy",
      };
  }
};

const determineIcon = ({ type }: { type: string }) => {
  switch (type) {
    case "layergroups":
      return "map";
    case "info":
    case "page":
      return "clipboard";
    case "participation":
      return "conversation";
    case "pano":
      return "vr";
    case "tours":
      return "tour";
  }
  return type;
};

const filterSupportedMenuItemTypes = (item: MenuItem) =>
  !["taxonomy", "faq", "post", "scenario"].includes(item.type);

export enum ReservedMenuIndices {
  Home = -1,
  About = -2,
  Debug = -3,
}
