export const localOrigins: string[] = [
  window.origin.endsWith("/") ? window.origin : window.origin + "/",
  "/"
];

if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_PROXY_HOST !== window.origin) {
    const proxyHost = process.env.REACT_APP_PROXY_HOST!;
    localOrigins.push(proxyHost.endsWith("/") ? proxyHost : proxyHost + "/");
  }
}

export const isLocalUrl = (url: string) => localOrigins.some((o) => url.startsWith(o));

export const mergeSearchParams = (a: string | URL | URLSearchParams, b: string | URL | URLSearchParams) => {
  const aParams = new URLSearchParams(a instanceof URL ? a.search : a);
  const bParams = new URLSearchParams(b instanceof URL ? b.search : b);

  bParams.forEach((value, key) => {
    aParams.set(key, value);
  });

  return aParams;
}