import React, { Suspense, useMemo, useState } from "react";
import Map from "./components/Map/Map";
import { Route, Redirect, Switch } from "react-router-dom";
import { Content } from "./components/Content/Content";
import Pano from "./components/Panorama/Pano";
import { Project } from "./components/Project/Project";
import { useAppState } from "./AppContext";
import Skeleton from "react-loading-skeleton";
import { ConceptBar, Layout, SkeletonContainer } from "./AppStyles";
import { MobileHomePage } from "./components/Content/MobileHomePage";
import MobilePopup from "./components/Content/MobilePopup";
import NavigationMobile from "./components/Navigation/NavigationMobile";
import Sidebar from "./components/Sidebar/Sidebar";
import AnimatedRoute from "./shared/components/AnimatedRoute";
import IsMobile from "./shared/components/IsMobile";
import { Tour } from "./components/Tour/Tour";
import { EmbedSidebar } from "./components/Sidebar/EmbedSidebar";
import { FallbackSidebarFocus } from "./components/Sidebar/FallbackSidebarFocus";
import { OnboardModal } from "./components/Modals/OnboardModal";
import IsDesktop from "./shared/components/IsDeskop";
import { ImageViewer } from "./shared/components/ImageViewer";
import { useUrlState } from "./hooks/useUrlState";
import { useEditState } from "./EditContext";
import { ParticipationResults } from "./components/Participation/ParticipationResults";
import { useFeature, Feature } from "./hooks/useFeature";
import classNames from "classnames";
import { FallbackSidebar } from "./components/Sidebar/FallbackSidebar";
import { FormattedMessage } from "react-intl";
import Landing from "./components/Landing/Landing";
import { Entrance } from "./shared/components/Entrance";
import { useInterfaceState } from "./InterfaceContext";
import { SurveyContainer } from "./tim-survey/SurveyContainer";
import { PhantomPanoAlertModal } from "./components/Modals/PhantomPanoAlertModal";
const Participation = React.lazy(() => import("./components/Participation/Participation"));
const UserQuestion = React.lazy(() => import("./components/Sidebar/UserQuestion"));
const EditorSidebar = React.lazy(() => import("./components/Sidebar/EditorSidebar"));

function AppContent() {
  const { state, showDraft } = useAppState();
  const { isInVr } = useInterfaceState();
  const urlState = useUrlState();
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const { editing } = useEditState();
  const participationAvailable = useFeature(Feature.Participation);
  const participationResultsAvailable = useFeature(Feature.ParticipationResults);
  const askQuestionAvailable = useFeature(Feature.AskQuestion);
  const faqsAvailable = useFeature(Feature.Faqs);

  const onImageClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (event.target instanceof HTMLImageElement) {
      setSelectedImg(event.target.src);
    }
  };

  const contentLayoutPaths = useMemo(() => {
    const paths = ["*/c/:contentSlug"];
    if (faqsAvailable) paths.unshift("*/faq/:faqSlug");
    return paths;
  }, [faqsAvailable]);

  const focusLayoutPaths = useMemo(() => {
    const paths = ["/tour/:tourSlug"];
    if (participationAvailable) paths.push("*/participation/:participationItemSlug");
    if (askQuestionAvailable) paths.push("*/question");
    return paths;
  }, [participationAvailable, askQuestionAvailable]);

  const innerContent = (
    <>
      {!isInVr && (
        <>
          {showDraft && (
            <ConceptBar>
              {editing ? (
                <FormattedMessage
                  id="conceptbar.edit-mode"
                  defaultMessage="Edit Mode"
                  description="Concept bar text declaring that the application is currently in edit mode."
                />
              ) : (
                <FormattedMessage
                  id="conceptbar.concept-mode"
                  defaultMessage="Draft mode"
                  description="Concept bar text declaring that the application is currently in draft mode."
                />
              )}
            </ConceptBar>
          )}
          <Switch>
            <Route path={["/map/*/pano", "/map/pano", "/pano/*/map"]} />
            <NavigationMobile />
          </Switch>

          {/* sidebar switch */}
          {!state.initializing && (
            <Switch>
              {editing && (
                <Route>
                  <Suspense fallback={<FallbackSidebar />}>
                    <EditorSidebar />
                  </Suspense>
                </Route>
              )}
              {askQuestionAvailable && (
                <Route path="*/question">
                  <Suspense fallback={<FallbackSidebarFocus />}>
                    <UserQuestion />
                  </Suspense>
                </Route>
              )}
              {participationAvailable && (
                <Route path="*/participation/:participationItemSlug">
                  <Suspense fallback={<FallbackSidebarFocus />}>
                    <Participation onClick={onImageClick} />
                  </Suspense>
                  <SurveyContainer />
                </Route>
              )}
              <Route path={["/tour/:tourSlug/step/:tourPointSlug", "/tour/:tourSlug"]}>
                <Tour />
              </Route>
              {/* Hack to remove the sidebar when loading, but we show it again later in this file to not double it. Needs serious refactoring */}
              <Route path={["/map/pano/:panoSlug", "/map/*/pano/:panoSlug", "/map", "/pano", "/"]}>
                {urlState.embed ? <EmbedSidebar /> : <Sidebar />}
              </Route>
            </Switch>
          )}

          <AnimatedRoute path={contentLayoutPaths} component={Content} />

          <IsMobile>
            <Switch>
              <AnimatedRoute path="*/p/:poiSlug" component={MobilePopup} />
              <AnimatedRoute path="/map" component={MobileHomePage} />
            </Switch>
          </IsMobile>
        </>
      )}

      {!state.initializing ? (
        <>
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path={["/@*", "/!*"]}>
              <Landing />
            </Route>
            <Route path={["/tour/:tourSlug/step/:tourPointSlug", "/tour/:tourSlug"]}>
              <Entrance>
                <Map />
                <Pano />
              </Entrance>
            </Route>
            {participationResultsAvailable && (
              <Route path="*/participation-result/:participationItemResultSlug">
                <Entrance>
                  <Map />
                  <ParticipationResults />
                </Entrance>
              </Route>
            )}
            <Route path="/map">
              <Entrance>
                <Map />
              </Entrance>
            </Route>
            <Route path="/pano/:panoSlug">
              <Entrance>
                <Pano />
              </Entrance>
            </Route>
            <Route path={["/project/:projectSlug/phase/:phaseSlug", "/project/:projectSlug"]}>
              <Entrance>
                <Project />
              </Entrance>
            </Route>
            <Redirect to="/" />
          </Switch>
          <OnboardModal />
          <PhantomPanoAlertModal />
        </>
      ) : (
        <SkeletonContainer>
          {/** @ts-ignore */}
          <Skeleton circle width={200} height={200} />
        </SkeletonContainer>
      )}
      {selectedImg && (
        <IsDesktop>
          <ImageViewer url={selectedImg} onClose={() => setSelectedImg(null)} />
        </IsDesktop>
      )}
    </>
  );

  return (
    <Switch>
      <Route path={focusLayoutPaths}>
        {/* Hack to show a nice sidebar while loading */}
        <Layout
          className={classNames({
            focus: !state.initializing,
            draft: showDraft && !isInVr,
          })}
        >
          {innerContent}
        </Layout>
      </Route>
      <Route>
        <Layout
          className={classNames({
            draft: showDraft && !isInVr,
          })}
        >
          {innerContent}
        </Layout>
      </Route>
    </Switch>
  );
}

export default AppContent;
