import { RefObject, useCallback } from "react";

interface UseCopy {
  (inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>): (text?: string) => Promise<void>;
  (): (text: string) => Promise<void>;
}

export const useCopy: UseCopy = (inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>) => {
  const copy = useCallback(async (text: string | undefined = inputRef?.current?.value) => {
    if (!text) {
      throw new Error("No text to copy");
    }

    if (window.navigator.clipboard) {
      await window.navigator.clipboard.writeText(text);
    } else {
      if (inputRef?.current) {
        inputRef.current.value = text;
        inputRef.current.focus();
        inputRef.current.select();
        document.execCommand("copy");
        document.getSelection()?.removeAllRanges();
        inputRef.current.blur();
      } else {
        const inputc = document.createElement("input");
        inputc.hidden = true; // prevent layout shift
        document.body.appendChild(inputc);
        inputc.value = text;
        inputc.focus();
        inputc.select();
        document.execCommand("copy");
        document.body.removeChild(inputc);
      }
    }
  }, [inputRef]);

  return copy;
}
