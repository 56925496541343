import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useInterfaceState } from "../../InterfaceContext";
import { Modal } from "../../shared/components/Modal";

export const PhantomPanoAlertModal = () => {
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const { phantomPanoAlertOpen } = interfaceState;

  const closeModal = useCallback(() => {
    setInterfaceState((state) => ({
      ...state,
      phantomPanoAlertOpen: false,
    }));
  }, [setInterfaceState]);

  return <Modal open={phantomPanoAlertOpen} onClose={closeModal}>
    <FormattedMessage tagName="h1" id="phantom-pano-alert-modal.title" defaultMessage="Panorama not available" />
    <FormattedMessage tagName="p" id="phantom-pano-alert-modal.body-1" defaultMessage="The requested panorama is not available in the current scenario." />
    <FormattedMessage tagName="p" id="phantom-pano-alert-modal.body-2" defaultMessage="Please select a different scenario and try again." />
  </Modal>
}