import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Feature, useFeature } from "../../hooks/useFeature";
import { useMapUrl } from "../../hooks/useMapUrl";
import { usePanoUrl } from "../../hooks/usePanoUrl";
import { usePoiUrl } from "../../hooks/usePoiUrl";
import { useRouteState } from "../../hooks/useRouteState";
import { SceneHistory, useSceneUrl } from "../../hooks/useSceneUrl";
import { useInterfaceState } from "../../InterfaceContext";
import { KrpanoElement } from "../../libs/krpano";
import { krpanoId } from "../../utils/panoUtils";
import { ThreeMessage, ThreeMessageCommand } from "../../utils/threeUtils";
import { CloseIconSpan, PanoCloseLink } from "../Panorama/PanoStyles";
import { ReactComponent as CloseIcon } from "./../../images/close.svg";

let origin = window.location.origin;
if (process.env.NODE_ENV === "development") {
  origin = process.env.REACT_APP_PROXY_HOST!;

  if (process.env.REACT_APP_THREE_HOST) {
    origin = process.env.REACT_APP_THREE_HOST;
  }
}

const lockedFrameRate = 1;

export const ThreeViewer = () => {
  const isAvailable = useFeature(Feature.ThreeViewer);
  const history = useHistory<SceneHistory>();
  const sceneUrl = useSceneUrl();
  const { sceneSlug } = useRouteState();
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const [sidebarWasOpen] = useState(interfaceState.sidebarOpen);

  const panoUrl = usePanoUrl();
  const poiUrl = usePoiUrl();
  const mapUrl = useMapUrl();

  const frameRef = React.useRef<HTMLIFrameElement>(null);
  const [port, setPort] = useState<MessagePort>();

  const krpano = useMemo(() => document.querySelector<KrpanoElement>("#" + krpanoId), []);

  const lockFrameRate = useCallback(() => {
    if (krpano) {
      krpano.blur();
      krpano.set("display.framerate", lockedFrameRate);
      krpano.call("stopmovements(); freezeview(true); autorotate.pause();");
    }
  }, [krpano]);

  const unlockFrameRate = useCallback(() => {
    if (krpano) {
      krpano.set("display.framerate", "auto");
      krpano.call("freezeview(false); autorotate.resume();");
    }
  }, [krpano]);

  useLayoutEffect(() => {
    lockFrameRate();
    return unlockFrameRate;
  }, [lockFrameRate, unlockFrameRate]);

  useEffect(() => {
    requestAnimationFrame(() =>
      setInterfaceState((prevState) => ({
        ...prevState,
        sidebarOpen: false,
      }))
    );
    return () =>
      setInterfaceState((prevState) => ({
        ...prevState,
        sidebarOpen: sidebarWasOpen,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!isAvailable) {
      if (history.location.state?.lastPano || history.location.state?.lastMapOpen) {
        history.goBack();
      } else {
        history.replace(sceneUrl(false));
      }
    }
  }, [isAvailable, history, sceneUrl]);

  const closeUrl = useMemo(() => {
    if (history.location.state?.lastPano) {
      return panoUrl(history.location.state.lastPano);
    } else if (history.location.state?.lastMapOpen) {
      return mapUrl();
    }
    
    return sceneUrl(false);
  }, [history.location.state, panoUrl, mapUrl, sceneUrl]);

  useLayoutEffect(() => {
    const initListener = (event: MessageEvent<any>) => {
      const message = event.data;
      if (message.command === ThreeMessageCommand.Init) {
        window.removeEventListener("message", initListener);
        if (
          event.ports?.[0] &&
          event.data.transaction &&
          event.data.command === ThreeMessageCommand.Init
        ) {
          event.ports[0].postMessage({
            transaction: event.data.transaction,
            command: ThreeMessageCommand.Init,
            data: { origin: window.origin },
          });
          setPort(event.ports[0]);
        }
      }
    };
    window.addEventListener("message", initListener);
    return () => window.removeEventListener("message", initListener);
  }, []);

  const onPortMessage = useCallback(
    (event: MessageEvent<ThreeMessage>) => {
      if (!port) return;
      const message = event.data;
      const response: ThreeMessage = {
        transaction: message.transaction,
        command: message.command,
        data: {},
      };
      switch (message.command) {
        case ThreeMessageCommand.Close:
          if (message.data.error) {
            console.error(`Viewer has closed because a fatal error occured: ${message.data.error}`);
          }
          history.push(closeUrl, { lastScene: sceneSlug })
          response.data.close = true;
          break;
        case ThreeMessageCommand.Pano:
          history.push(panoUrl(message.data.pano), { lastScene: sceneSlug });
          response.data.close = true;
          break;
        case ThreeMessageCommand.Poi:
          history.push(poiUrl(message.data.poi), { lastScene: sceneSlug });
          response.data.close = true;
          break;
        case ThreeMessageCommand.Map:
          history.push(mapUrl(), { lastScene: sceneSlug });
          response.data.close = true;
          break;
      }

      port.postMessage(response);
    },
    [history, panoUrl, poiUrl, mapUrl, closeUrl, sceneSlug, port]
  );

  useEffect(() => {
    if (port) {
      port.addEventListener("message", onPortMessage);
      port.start();
      return () => port.removeEventListener("message", onPortMessage);
    }
  }, [port, onPortMessage]);

  if (!isAvailable || !sceneSlug) return null;

  return (
    <Wrapper>
      <iframe
        ref={frameRef}
        title="ThreeViewer"
        src={`${origin}/three/scene/${sceneSlug}`}
        style={{ border: "none" }}
        width="100%"
        height="100%"
      />
      <PanoCloseLink to={{ pathname: closeUrl, state: { lastScene: sceneSlug } }}>
        <CloseIconSpan>
          <CloseIcon />
        </CloseIconSpan>
      </PanoCloseLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--space-default);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 70;
  overflow: hidden;

  > :first-child {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--space-10);
    overflow: hidden;
  }
`;
