import { useCallback, useMemo, useState } from "react";
import {
  getLocalStorage,
  getSessionStorage,
  mappedLocalStorage,
  mappedSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "../utils/corsUtils";

type UseStorageReturn = [string | null, (value: string) => void, () => void];

let localStorageUpdate = 0;
let sessionStorageUpdate = 0;

export const useLocalStorage = (key: string): UseStorageReturn => {
  const [, setUpdate] = useState(localStorageUpdate);
  const update = localStorageUpdate;

  const value = useMemo(() => {
    if (mappedLocalStorage.has(key)) return mappedLocalStorage.get(key)!;

    getLocalStorage(key).then(value => {
      if (update === localStorageUpdate && value) {
        mappedLocalStorage.set(key, value);
        setUpdate(++localStorageUpdate);
      }
    });

    return null;
  }, [key, update, setUpdate]);
  
  const setValue = useCallback(
    async (value: string) => {
      await setLocalStorage(key, value);
      mappedLocalStorage.set(key, value);
      setUpdate(++localStorageUpdate);
    },
    [key, setUpdate]
  );

  const removeValue = useCallback(async () => {
    await removeLocalStorage(key);
    mappedLocalStorage.delete(key);
    setUpdate(++localStorageUpdate);
  }, [key, setUpdate]);

  return [value, setValue, removeValue];
};

export const useSessionStorage = (key: string): UseStorageReturn => {
  const [, setUpdate] = useState(sessionStorageUpdate);
  const update = sessionStorageUpdate;
  
  const value = useMemo(() => {
    if (mappedSessionStorage.has(key)) return mappedSessionStorage.get(key)!;

    getSessionStorage(key).then(value => {
      if (update === sessionStorageUpdate && value) {
        mappedSessionStorage.set(key, value);
        setUpdate(++sessionStorageUpdate);
      }
    });

    return null;
  }, [key, update, setUpdate]);

  const setValue = useCallback(
    async (value: string) => {
      await setSessionStorage(key, value);
      mappedSessionStorage.set(key, value);
      setUpdate(++sessionStorageUpdate);
    },
    [key]
  );

  const removeValue = useCallback(async () => {
    await removeSessionStorage(key);
    mappedSessionStorage.delete(key);
    setUpdate(++sessionStorageUpdate);
  }, [key]);

  return [value, setValue, removeValue];
};
