import { useMemo, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { messages } from "../global-intl-messages";
import { useParticipationItemUrl } from "../hooks/useParticipationItemUrl";
import Button from "../shared/components/Button";
import {
  findAnswer,
  isBooleanQuestion,
  isChoiceQuestion,
  isFileUploadQuestion,
  isLocationQuestion,
  isRatingQuestion,
  isTextQuestion,
  isTrackingQuestion,
  useSurveyState,
} from "../SurveyContext";
import {
  booleanAnswered,
  minimumPinsPlaced,
  optionSelected,
  ratingAnswered,
  trackingAnswered,
} from "../utils/surveyUtils";
import { validateTextQuestion } from "./OpenQuestion";
import { useInterfaceState } from "../InterfaceContext";
import { useAppState } from "../AppContext";

export const SurveyNavigation = () => {
  const { surveyState, setSurveyState, onCompleteFn, api } = useSurveyState();
  const { survey, activePageId } = surveyState;
  const { state } = useAppState();
  const { setParticipationState } = useInterfaceState();
  const participationItemUrl = useParticipationItemUrl();
  const history = useHistory();

  const page = useMemo(() => {
    return survey?.pages.find((p) => p.id === activePageId);
  }, [activePageId, survey]);
  const index = useMemo(() => {
    return page ? survey?.pages.indexOf(page) ?? 0 : 0;
  }, [page, survey]);

  useEffect(() => {
    console.log("page", page);
    if (!page) return;
    if (page.layer && page.panorama)
      return console.error("[TIM.Survey]: Page cannot have both a layer and a panorama assigned!");

    if (page.layer) {
      const layer = state.map.layerGroups.find((l) => l.id === page.layer);
      if (layer) {
        setParticipationState({
          layerSlug: layer.slug,
          scenarioSlug: "",
          panoSlug: "",
          navigatedPano: false,
        });
      }
    } else if (page.panorama) {
      const panorama = state.panos.find((p) => p.id === page.panorama);
      if (panorama) {
        setParticipationState({
          layerSlug: "",
          scenarioSlug: "",
          panoSlug: panorama.slug,
          navigatedPano: false,
        });
      }
    }
  }, [page, setParticipationState, state.map.layerGroups, state.panos]);

  const setActivePage = useCallback(
    (pageId: number) => {
      setSurveyState((prevState) => ({ ...prevState, activePageId: pageId }));
    },
    [setSurveyState]
  );

  const previousPageId = useMemo(() => {
    if (index === 0) return null;
    return survey?.pages[index - 1].id;
  }, [index, survey?.pages]);

  const nextPageId = useMemo(() => {
    if (index === (survey?.pages ? survey.pages.length - 1 : -1)) return null;
    return survey?.pages[index + 1].id;
  }, [index, survey?.pages]);

  const canContinue = useMemo(() => {
    if (!page) return false;
    return (
      page.elements
        .filter(isLocationQuestion)
        .every((e) => minimumPinsPlaced(e, surveyState.answers.find(findAnswer(e)))) &&
      page.elements
        .filter(isTextQuestion)
        .every((e) => validateTextQuestion(e, surveyState.answers.find(findAnswer(e)))) &&
      page.elements
        .filter(isFileUploadQuestion)
        .every((e) => !e.required || !!surveyState.answers.find(findAnswer(e))?.amountOfFiles) &&
      page.elements
        .filter(isChoiceQuestion)
        .every((e) => optionSelected(e, surveyState.answers.find(findAnswer(e)))) &&
      page.elements
        .filter(isBooleanQuestion)
        .every((e) => booleanAnswered(e, surveyState.answers.find(findAnswer(e)))) &&
      page.elements
        .filter(isRatingQuestion)
        .every((e) => ratingAnswered(e, surveyState.answers.find(findAnswer(e)))) &&
      page.elements
        .filter(isTrackingQuestion)
        .every((e) => trackingAnswered(e, surveyState.answers.find(findAnswer(e))))
    );
  }, [page, surveyState]);

  const onComplete = useCallback(async () => {
    onCompleteFn().then(async (r) => {
      if (r && r.status === 200) {
        await Promise.all(
          r.data.map(async (e) => {
            const files = surveyState.files?.[e.questionId];
            if (files?.length) {
              for (const file of files) {
                return api.files
                  .uploadFile(e.answerId, { file })
                  .catch((e) =>
                    console.error(`[Tim.Survey.FileUpload]: Upload failed (${file.name})`)
                  );
              }
            } else {
              console.error(`[Tim.Survey.FileUpload]: No files found for question ${e.questionId}`);
            }
          })
        );
      }
      setSurveyState((prevState) => ({
        ...prevState,
        answers: [],
        activePageId: undefined,
        activeQuestionId: undefined,
        activePinId: undefined,
        files: {},
      }));
      history.push(participationItemUrl());
    });
  }, [api.files, history, onCompleteFn, participationItemUrl, setSurveyState, surveyState.files]);

  return (
    <StyledSurveyNavigation>
      <div>
        {previousPageId && (
          <Button onClick={() => setActivePage(previousPageId)}>
            <FormattedMessage {...messages.previousButton} />
          </Button>
        )}
      </div>
      <div>
        {nextPageId && (
          <Button
            onClick={() => setActivePage(nextPageId)}
            className={canContinue ? "primary" : "disabled"}
            disabled={!canContinue}
          >
            <FormattedMessage {...messages.nextButton} />
          </Button>
        )}
        {!nextPageId && (
          <Button
            onClick={onComplete}
            className={canContinue ? "primary" : "disabled"}
            disabled={!canContinue}
          >
            <FormattedMessage {...messages.finishButton} />
          </Button>
        )}
      </div>
    </StyledSurveyNavigation>
  );
};

const StyledSurveyNavigation = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  border-top: 1px solid var(--color-neutral-10);
  padding-top: 1rem;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 1rem;
`;
