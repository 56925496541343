import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { SurveyArgumentModal } from "./tim-survey/SurveyArgumentModal";
import {
  EndUserApi as Api,
  BooleanQuestionModel,
  ChoiceQuestionModel,
  CreateAnswerModel,
  CreateBooleanAnswerModel,
  CreateChoiceAnswerModel,
  CreateFileUploadAnswerModel,
  CreateLocationPickerAnswerModel,
  CreateRatingAnswerModel,
  CreateTextAnswerModel,
  ElementModel,
  FileUploadEndpointReferenceModel,
  FileUploadQuestionModel,
  GeoLocationPinModel,
  HttpResponse,
  LocationPickerQuestionModel,
  PanoLocationPinModel,
  QuestionModel,
  QuestionnaireModel,
  RatingQuestionModel,
  TextQuestionModel,
  TrackingQuestionModel,
  CreateTrackingAnswerModel,
} from "./tim-survey/EndUserApi";
import usePromise from "./hooks/usePromise";
import { useAppState } from "./AppContext";
import { UseStateReturn } from "./types";
import { Capability, useCapability } from "./hooks/useCapability";
import { useIntl } from "react-intl";
import { SurveyWarningModal } from "./components/Modals/SurveyWarningModal";
import { RestrictionLayerType } from "./tim-survey/enums";

export type Question = {
  id: string;
  title: string;
  description?: string;
  type: "place-pin" | "radio";
};

export function isLocationQuestion(element: ElementModel): element is LocationPickerQuestionModel {
  return element.type === "LocationPickerQuestion";
}

export function isTextQuestion(element: ElementModel): element is TextQuestionModel {
  return element.type === "TextQuestion";
}

export function isBooleanQuestion(element: ElementModel): element is BooleanQuestionModel {
  return element.type === "BooleanQuestion";
}

export function isRatingQuestion(element: ElementModel): element is RatingQuestionModel {
  return element.type === "RatingQuestion";
}

export function isChoiceQuestion(element: ElementModel): element is ChoiceQuestionModel {
  return element.type === "ChoiceQuestion";
}

export function isFileUploadQuestion(element: ElementModel): element is FileUploadQuestionModel {
  return element.type === "FileUploadQuestion";
}

export function isTrackingQuestion(element: ElementModel): element is TrackingQuestionModel {
  return element.type === "TrackingQuestion";
}

export function isElementQuestion(element: ElementModel): element is ElementModel {
  return element.type === "Element";
}
export function isQuestion(element: ElementModel): element is QuestionModel {
  return element.type.includes("Question");
}

/**
 * Typed predicate factory to retrieve answers on a question, based on the passed question
 *
 * Extend the type of the predicate to match the type of the question when new question/answer types are added
 * @example `T extends {{Type}}QuestionModel ? (a: CreateAnswerModel) => a is Create{{Type}}AnswerModel`
 *
 * @returns a type predicate that can be used to filter answers by question id
 */
export const findAnswer = <T extends QuestionModel>(
  element: T
): T extends LocationPickerQuestionModel
  ? (a: CreateAnswerModel) => a is CreateLocationPickerAnswerModel
  : T extends RatingQuestionModel
  ? (a: CreateAnswerModel) => a is CreateRatingAnswerModel
  : T extends BooleanQuestionModel
  ? (a: CreateAnswerModel) => a is CreateBooleanAnswerModel
  : T extends ChoiceQuestionModel
  ? (a: CreateAnswerModel) => a is CreateChoiceAnswerModel
  : T extends TextQuestionModel
  ? (a: CreateAnswerModel) => a is CreateTextAnswerModel
  : T extends FileUploadQuestionModel
  ? (a: CreateAnswerModel) => a is CreateFileUploadAnswerModel
  : T extends TrackingQuestionModel
  ? (a: CreateAnswerModel) => a is CreateTrackingAnswerModel
  : (a: CreateAnswerModel) => a is CreateAnswerModel => {
  return ((a: CreateAnswerModel) => a.questionId === element.id) as any;
};

export type LocalPinModel = (PanoLocationPinModel | GeoLocationPinModel) & { id: number };

enum ApiErrorType {
  SingleEntryPolicyViolation = "https://docs.theimagineers.com/errors/survey/single-entry-policy-violation",
  NotFound = "https://tools.ietf.org/html/rfc7231#section-6.5.4",
  Unauthorized = "https://tools.ietf.org/html/rfc7235#section-3.1",
}

const surveyUrl = () => {
  const localhost = /^(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d+)?$/.exec(
    window.location.host
  );
  if (localhost) {
    return process.env.REACT_APP_SURVEY_API_HOST || `https://${localhost[1]}:45011`;
  } else if (window.location.host.includes("staging-ik-doe-mee")) {
    return "https://app-tim-survey-end-user-api-cd-westeu-00-development.azurewebsites.net";
  }
  return "https://api.survey.theimagineers.com";
};

type SurveyState = {
  activePageId?: number;
  survey?: QuestionnaireModel;
  activeQuestionId?: string;
  activePinId?: number;
  questions: LocationPickerQuestionModel[];
  modalOpen: boolean;
  answers: CreateAnswerModel[];
  files?: {
    [x: number]: FileList | null;
  };
};

// @ts-ignore
const initialSurveyState: SurveyState = {
  activePageId: 0,
  survey: undefined,
  activeQuestionId: "a",
  activePinId: undefined,
  questions: [],
  modalOpen: false,
  answers: [],
};

interface PlacePinOptions {
  location: { latitude: number; longitude: number };
  scenario: string;
  featuresAtLocation?: mapboxgl.MapboxGeoJSONFeature[];
}
export interface PlaceGeoPinOptions extends PlacePinOptions {
  layer: string;
}
export interface PlacePanoPinOptions extends PlacePinOptions {
  pano: string;
}
const isPlaceGeoPinOptions = (options: PlacePinOptions): options is PlaceGeoPinOptions =>
  options.hasOwnProperty("layer");
const isPlacePanoPinOptions = (options: PlacePinOptions): options is PlacePanoPinOptions =>
  options.hasOwnProperty("pano");

const SurveyStateContext = createContext<{
  surveyState: SurveyState;
  setSurveyState: Dispatch<SetStateAction<SurveyState>>;
  pinPlaceFn: (options: PlacePinOptions) => void;
  openModalFn: (id: number) => void;
  onCompleteFn: () => Promise<false | HttpResponse<FileUploadEndpointReferenceModel[], any>>;
  setActiveSurvey: UseStateReturn<number>[1];
  api: Api<unknown>;
  error?: { title: string; message: string };
  setError: UseStateReturn<{ title: string; message: string } | undefined>[1];
}>({
  surveyState: initialSurveyState,
  setSurveyState: () => {},
  pinPlaceFn: (options: PlacePinOptions) => {},
  openModalFn: (id: number) => {},
  onCompleteFn: () => Promise.resolve(false),
  setActiveSurvey: () => {},
  api: new Api<unknown>(),
  setError: () => {},
});

// Fallback for when third-party cookies are not usable (e.g. in incognito mode)
const completedSurveyIds = new Set<number>();

export const SurveyStateProvider = ({ children }: any) => {
  const [activeSurvey, setActiveSurvey] = useState(0);
  const [surveyState, setSurveyState] = useState(initialSurveyState);
  const { state } = useAppState();
  const [lastAnswerId, setLastAnswerId] = useState<number | null>(null);
  const [surveyWarningModalMessage, setSurveyWarningModalMessage] = useState<string | null>(null);
  const [error, setError] = useState<{ title: string; message: string }>();
  const intl = useIntl();
  const excemptFromSingleEntryPolicy = useCapability(Capability.ReadDraft);

  const getLayerIdBySlug = useCallback(
    (slug: string) => state.map.layerGroups.find((layer: any) => layer.slug === slug)?.id ?? 0,
    [state.map?.layerGroups]
  );

  const getScenarioIdBySlug = useCallback(
    (slug: string) => state.scenarios.find((scenario: any) => scenario.slug === slug)?.id ?? 0,
    [state.scenarios]
  );

  const getPanoIdBySlug = useCallback(
    (slug: string) => state.panos.find((pano: any) => pano.slug === slug)?.id ?? 0,
    [state.panos]
  );

  const apiKey = useMemo(
    () => state.participation?.find((p) => p.surveyId === `tim.survey://${activeSurvey}`)?.apiKey,
    [state.participation, activeSurvey]
  );

  const api = useMemo(() => {
    return new Api({
      baseUrl: surveyUrl(),
      baseApiParams: {
        credentials: excemptFromSingleEntryPolicy ? "omit" : "include",
      },
      securityWorker() {
        if (apiKey) return { headers: { "X-ApiKey": apiKey } };
      },
    });
  }, [excemptFromSingleEntryPolicy, apiKey]);

  const [survey] = usePromise(async () => {
    if (!activeSurvey) return;

    let r;
    if (completedSurveyIds.has(activeSurvey) && !excemptFromSingleEntryPolicy) {
      // Incognito mode fallback
      r = { ok: false, error: { type: ApiErrorType.SingleEntryPolicyViolation } };
    } else {
      r = await api.surveys.getSurvey(activeSurvey).catch((r) => r);
    }

    if (!r.ok) {
      switch (r.error.type) {
        case ApiErrorType.SingleEntryPolicyViolation:
          setError({
            title: intl.formatMessage({
              id: "survey.errors.single-entry-policy-violation.title",
              defaultMessage: "Survey completed already",
              description:
                "Error title when loading a survey fails due to the survey being completed before.",
            }),
            message: intl.formatMessage({
              id: "survey.errors.single-entry-policy-violation.message",
              defaultMessage: "You have already participated in this survey.",
              description:
                "Error message when loading a survey fails due to the survey being completed before.",
            }),
          });
          completedSurveyIds.add(activeSurvey);
          break;

        case ApiErrorType.NotFound:
          setError({
            title: intl.formatMessage({
              id: "survey.errors.not-found.title",
              defaultMessage: "Survey not found",
              description:
                "Error title when loading a survey fails due to it not being available in the current platform.",
            }),
            message: intl.formatMessage({
              id: "survey.errors.not-found.message",
              defaultMessage: "The survey you are trying to participate in does not exist.",
              description:
                "Error message when loading a survey fails due to it not being available in the current platform.",
            }),
          });
          break;

        default:
          setError({
            title: intl.formatMessage({
              id: "survey.errors.default.title",
              defaultMessage: "An error occurred",
              description: "Default error title when loading a survey fails.",
            }),
            message:
              r.error.detail ||
              intl.formatMessage({
                id: "survey.errors.default.message",
                defaultMessage: "The survey you are trying to participate in failed to load.",
                description: "Default error message when loading a survey fails.",
              }),
          });
      }

      setActiveSurvey(0);

      return;
    }

    return r.data;
  }, [api.surveys, activeSurvey, excemptFromSingleEntryPolicy, intl]);

  const onComplete = useCallback(async () => {
    if (!activeSurvey) return false;

    const result = await api.surveys.createResponse(activeSurvey, {
      answers: surveyState.answers,
    });

    if (!excemptFromSingleEntryPolicy) {
      completedSurveyIds.add(activeSurvey);
    }

    setActiveSurvey(0);

    return result;
  }, [
    api.surveys,
    surveyState.answers,
    activeSurvey,
    setActiveSurvey,
    excemptFromSingleEntryPolicy,
  ]);

  useEffect(() => {
    if (survey && activeSurvey) {
      setSurveyState((s) => ({ ...s, activePageId: survey.pages[0].id, survey }));
    } else {
      setSurveyState((s) => ({ ...s, activePageId: undefined, survey: undefined }));
    }
  }, [survey, activeSurvey]);

  const closeModal = useCallback(() => {
    setSurveyState((prevState) => ({ ...prevState, modalOpen: false }));
    setLastAnswerId(null);
  }, [setSurveyState, setLastAnswerId]);

  const activePage = useMemo(
    () => surveyState.survey?.pages.find((p) => p.id === surveyState.activePageId),
    [surveyState.activePageId, surveyState.survey]
  );

  const activeLocationQuestions = useMemo(
    () =>
      activePage?.elements.filter((e) => isLocationQuestion(e)) as
        | LocationPickerQuestionModel[]
        | undefined,
    [activePage]
  );

  const activeLocationAnswers = useMemo(
    () =>
      surveyState.answers.filter(
        (a): a is CreateLocationPickerAnswerModel =>
          activeLocationQuestions?.some((q) => q.id === a.questionId) ?? false
      ),
    [activeLocationQuestions, surveyState.answers]
  );

  const openModal = useCallback(
    (answerId: number) => {
      const answer = activeLocationAnswers.find((a) =>
        a.pins.some((p) => (p as LocalPinModel).id === answerId)
      );
      const pin = answer?.pins.find((p) => (p as LocalPinModel).id === answerId);
      const question = activeLocationQuestions?.find((e) => e.id === answer?.questionId);
      if (!question || !isLocationQuestion(question)) return;
      const questionPin = question.pins.find((p) => p.id === pin?.pinDefinition);
      if (!questionPin?.allowComment) return;
      setLastAnswerId(answerId);
      setSurveyState((prevState) => ({
        ...prevState,
        modalOpen: true,
      }));
    },
    [activeLocationAnswers, activeLocationQuestions, setLastAnswerId, setSurveyState]
  );

  const placePin = useCallback(
    (options: PlacePinOptions) => {
      if (!surveyState.activePinId) return;
      const question = activeLocationQuestions?.find((e) => {
        return e.pins.some((p) => p.id === surveyState.activePinId);
      });
      if (!question) return;

      const pin = question.pins.find((p) => p.id === surveyState.activePinId);
      if (!pin) return;
      const id = Date.now();
      const answer: CreateLocationPickerAnswerModel = activeLocationAnswers.find(
        (a) => a.questionId === question.id
      ) ?? {
        questionId: question.id,
        type: "LocationPickerAnswer",
        pins: [],
      };

      if (question.restrictions[0]) {
        const onRestrictedLayer = options.featuresAtLocation?.some((f) => {
          return question.restrictions[0].cluster[0].split(",").some((c) => {
            return f.layer?.id === c;
          });
        });

        if (
          (!onRestrictedLayer &&
            question.restrictions[0].restrictionType === RestrictionLayerType.Mandatory) ||
          (onRestrictedLayer &&
            question.restrictions[0].restrictionType === RestrictionLayerType.Restricted)
        ) {
          setSurveyWarningModalMessage(question.restrictions[0].message);
          setSurveyState((prevState) => ({
            ...prevState,
            activePinId: undefined,
          }));
          return;
        }
      }

      if (isPlaceGeoPinOptions(options)) {
        const placedPin: GeoLocationPinModel & { id: number } = {
          latitude: options.location.latitude,
          longitude: options.location.longitude,
          comment: "",
          pinDefinition: pin.id,
          id: id,
          layerId: getLayerIdBySlug(options.layer),
          scenarioId: getScenarioIdBySlug(options.scenario),
          type: "GeoLocation",
        };

        answer.pins.push(placedPin);
      } else if (isPlacePanoPinOptions(options)) {
        const placedPin: PanoLocationPinModel & { id: number } = {
          latitude: options.location.latitude,
          longitude: options.location.longitude,
          comment: "",
          pinDefinition: pin.id,
          id: id,
          panoId: getPanoIdBySlug(options.pano),
          scenarioId: getScenarioIdBySlug(options.scenario),
          type: "PanoLocation",
        };

        answer.pins.push(placedPin);
      }

      const answers = surveyState.answers.map((a) =>
        a.questionId === answer?.questionId ? answer : a
      );
      if (!answers.includes(answer)) {
        answers.push(answer);
      }

      setLastAnswerId(id);
      setSurveyState((prevState) => ({
        ...prevState,
        activePinId: undefined,
        modalOpen: !!pin.allowComment,
        answers: answers,
      }));
    },
    [
      surveyState.activePinId,
      surveyState.answers,
      activeLocationQuestions,
      activeLocationAnswers,
      getLayerIdBySlug,
      getScenarioIdBySlug,
      getPanoIdBySlug,
    ]
  );

  return (
    <SurveyStateContext.Provider
      value={{
        surveyState,
        setSurveyState,
        pinPlaceFn: placePin,
        openModalFn: openModal,
        onCompleteFn: onComplete,
        setActiveSurvey,
        api,
        error,
        setError,
      }}
    >
      <SurveyArgumentModal
        open={surveyState.modalOpen}
        onClose={closeModal}
        answerId={lastAnswerId}
      />
      <SurveyWarningModal
        open={!!surveyWarningModalMessage}
        onClose={() => setSurveyWarningModalMessage(null)}
        message={surveyWarningModalMessage ?? undefined}
      />
      {children}
    </SurveyStateContext.Provider>
  );
};

export const useSurveyState = () => useContext(SurveyStateContext);
